import { BsCopy } from "react-icons/bs";
import toast from "react-hot-toast";
import { useState } from "react";

interface CopyTextProps {
  value: any;
  show?: boolean;
}

const CopyText = ({ value, show }: CopyTextProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleCopy = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard:", text);
      })
      .catch((error) => {
        toast.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <button
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    style={{ position: "relative", background: "none", border: "none", padding: "0" }}
    aria-label="Copy value"
    onKeyDown={(e) => {
      if (e.key === "Enter" || e.key === " ") {
        handleCopy(value);
      }
    }}
  >
    {show && <span>{value}</span>}
    {isHovered && (
      <BsCopy
        className="share-options"
        onClick={() => handleCopy(value)}
        style={{
          position: "absolute",
          top: "20px",
          right: "-5px",
          cursor: "pointer",
          transition: "transform 0.2s ease",
        }}
        aria-hidden="true"
      />
    )}
  </button>  
  );
};

export default CopyText;
