import { Box, Checkbox, FormControlLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Define the types for the row structure

interface ScanNewModalProps {
  isShowNewModal: boolean;
  settingModalSettingClose: () => void;
  shppingCostValue: any;
  handleDeleteRow: any;
  setIsCheckboxChecked: any;
  isCheckboxChecked: any;
  setShippingCostValue: any;
  handleAddRow: any;
  handleScanSettingChange: any;
  rows: any;
  shippingSettingsLoading: any;
  shippingSettingUpdates: any;
  shippingSettingsError: any;
  setShippingSettingsError: any;
  shippingRoleError: any;
  setShippingRoleError: any;
}

const ScanNewModal: React.FC<ScanNewModalProps> = ({
  isShowNewModal,
  settingModalSettingClose,
  shppingCostValue,
  handleDeleteRow,
  setIsCheckboxChecked,
  isCheckboxChecked,
  setShippingCostValue,
  handleAddRow,
  handleScanSettingChange,
  rows,
  shippingSettingsLoading,
  shippingSettingUpdates,
  shippingSettingsError,
  setShippingSettingsError,
  setShippingRoleError,
  shippingRoleError,
}) => {
  return (
    <div>
      <Modal
        show={isShowNewModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-filter-marketplace"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Rules Setting
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={25}
            onClick={settingModalSettingClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-start align-items-end scan-setting-text pt-3"
            >
              Shipping Cost Per Pound &nbsp;
              <RxQuestionMarkCircled />
            </Col>
            <Col md={6} className=" pt-3">
              <Box sx={{ "& > :not(style)": { width: "44.5ch" } }}>
                <TextField
                  id="input-with-icon-textfield"
                  label="Shipping Cost"
                  value={shppingCostValue}
                  onChange={(e) => setShippingCostValue(e.target.value)}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    },
                  }}
                  variant="standard"
                  disabled={isCheckboxChecked}
                  type="number"
                  inputProps={{ min: 0 }}
                />
              </Box>
            </Col>
            {shippingSettingsError && !shppingCostValue && (
              <div className="text-danger mt-2">
                Shipping Cost Per Pound is required when shipping rules are
                disabled.
              </div>
            )}
          </Row>
          <Row className="new-scan-box mt-4">
            <Col
              md={5}
              className="d-flex justify-content-start align-items-center scan-setting-text pt-3 "
            >
              Consider Shipping Rules &nbsp;
              <RxQuestionMarkCircled />
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-start align-items-center pt-3"
            >
              <Box sx={{ "& > :not(style)": { width: "4.5ch" } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckboxChecked}
                      onChange={(e) => {
                        setIsCheckboxChecked(e.target.checked);
                        setShippingCostValue("");
                        setShippingSettingsError(false);
                        setShippingRoleError(false);
                      }}
                    />
                  }
                  label=""
                />
              </Box>
            </Col>
          </Row>

          {rows
            .slice()
            .reverse()
            .map((row: any, index: any) => (
              <Row key={row.id} className="d-flex p-2 scan-input ">
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-end scan-setting-text pt-3"
                >
                  Shipping Rules &nbsp;
                  <RxQuestionMarkCircled />
                </Col>
                {/* From field */}
                <Col
                  md={2}
                  className="d-flex justify-content-center align-items-center pt-3"
                >
                  <Box sx={{ "& > :not(style)": { width: "14.5ch" } }}>
                    <TextField
                      value={row.from}
                      onChange={(e) =>
                        handleScanSettingChange(row.id, "from", e.target.value)
                      }
                      label="From"
                      variant="standard"
                      disabled={!isCheckboxChecked}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Col>
                {/* To field */}
                <Col
                  md={2}
                  className="d-flex justify-content-center align-items-center pt-3"
                >
                  <Box sx={{ "& > :not(style)": { width: "14.5ch" } }}>
                    <TextField
                      value={row.to}
                      onChange={(e) =>
                        handleScanSettingChange(row.id, "to", e.target.value)
                      }
                      label="To"
                      variant="standard"
                      disabled={!isCheckboxChecked}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Col>
                {/* Price field */}
                <Col
                  md={2}
                  className="d-flex justify-content-center align-items-center pt-3"
                >
                  <Box sx={{ "& > :not(style)": { width: "14.5ch" } }}>
                    <TextField
                      value={row.price}
                      onChange={(e) =>
                        handleScanSettingChange(row.id, "price", e.target.value)
                      }
                      label="Price"
                      variant="standard"
                      disabled={!isCheckboxChecked}
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Col>
                {index >= 0 && index < rows.length - 1 && (
                  <Col
                    md={2}
                    className="d-flex justify-content-end align-items-center pt-3"
                  >
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteRow(row.id)}
                      disabled={rows.length <= 1 || !isCheckboxChecked}
                    >
                      <RiDeleteBin5Fill />
                    </Button>
                  </Col>
                )}
                {index === rows.length - 1 && (
                  <Col
                    md={2}
                    className="d-flex justify-content-end align-items-center pt-3 mt-4"
                  >
                    <Button
                      className="new-scan-add-btn"
                      onClick={handleAddRow}
                      disabled={!isCheckboxChecked}
                    >
                      Add
                    </Button>
                  </Col>
                )}
                {shippingRoleError && (
                  <div className="text-danger">
                    At least one shipping rule (from, to, and cost) is required
                    when shipping rules are enabled.
                  </div>
                )}
              </Row>
            ))}
        </Modal.Body>
        <Modal.Footer className="mt-2 mb-2">
          <Row className="w-100 d-flex justify-content-end">
            <Col md={6} className="text-right d-flex justify-content-end gap-2">
              <Button
                variant="secondary"
                onClick={settingModalSettingClose}
                disabled={shippingSettingsLoading}
              >
                Close
              </Button>
              <Button
                onClick={shippingSettingUpdates}
                disabled={shippingSettingsLoading}
              >
                {shippingSettingsLoading ? "Loading..." : "update"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScanNewModal;
