import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tooltip } from "@mui/material";
import { useInfoStyles } from "../../../helpers";
type AlertLevel = {
  level: "success" | "warning" | "danger";
  message: string[];
};

type AlertBoxesProps = {
  alertLevels: AlertLevel[];
};

const AlertBoxes: React.FC<AlertBoxesProps> = ({ alertLevels }) => {
  const classes = useInfoStyles() as Record<string, string>;
  const getColor = (level: string): string => {
    switch (level) {
      case "success":
        return "#E9FBEF";
      case "warning":
        return "#FFF4D9";
      case "danger":
        return "#FEF3F2";
      default:
        return "gray";
    }
  };
  const getBorderColor = (level: string): string => {
    switch (level) {
      case "success":
        return "#87E8AD";
      case "warning":
        return "#DAA520";
      case "danger":
        return "#FDA19B";
      default:
        return "gray";
    }
  };

  const getTextColor = (level: string, hasMessages: boolean): string => {
    if (!hasMessages) return "lightgray";
    switch (level) {
      case "success":
        return "#414651";
      case "warning":
        return "#DAA520";
      case "danger":
        return "#B42419";
      default:
        return "#414651";
    }
  };

  return (
    <div className="d-flex gap-2">
      {alertLevels?.map((alert, index) => {
        const hasMessages = alert.message.length > 0;
        const boxContent = (
          <div
            key={`${alert}-${index}`}
            style={{
              backgroundColor: getColor(alert.level),
              borderColor: getBorderColor(alert.level),
              color: getTextColor(alert.level, hasMessages),
              borderRadius: "100px",
              width: "32px",
              borderWidth: "1px",
              borderStyle: "solid",
              height: "22px",
              padding: "7px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {alert.message.length}
          </div>
        );
        return hasMessages ? (
          <div key={`${alert}-${index}`} className="alert-tooltips">
            <Tooltip
              title={
                <div>
                  {alert.message.map((msg, idx) => (
                    <div className="text-start pt-1" key={`${msg}-${idx}`}>
                      {msg}
                    </div>
                  ))}
                </div>
              }
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              {boxContent}
            </Tooltip>
          </div>
        ) : (
          <div key={`${alert}-${index}`}>{boxContent}</div>
        );
      })}
    </div>
  );
};

export default AlertBoxes;
