import React from "react";

const KeepaChartTooltip = ({
  imageUrl,
  show,
  onClose,
  keepaImageCheck,
}: any) => {
  if (!show) return null;
  return (
    <button
      className="image-tooltip"
      onMouseEnter={() => {}}
      onMouseLeave={onClose}
      onClick={onClose}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClose();
        }
      }}
      aria-label="Close Tooltip"
      style={{ border: "none", padding: "0px" }}
    >
      {keepaImageCheck && (
        <div className="image-tooltip-content-keepa">
          <img src={imageUrl} alt="Product Preview" />
        </div>
      )}
    </button>
  );
};

export default KeepaChartTooltip;
