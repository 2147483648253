import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HCMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import "./index.css";

if (typeof Highcharts === "object") {
  HCMore(Highcharts);
}

type DebouncedFunction = (...args: any[]) => void;

declare module "highcharts" {
  interface Point {
    highlight(event: MouseEvent): void;
  }
}
interface ChartsProps {
  chartsData: any;
  legendStyle: any;
  dragActive?: any;
  filterNumber?:any;
  orignalData?: any
}

const ChartDemo = ({ chartsData, legendStyle, dragActive,filterNumber,orignalData }: ChartsProps) => {
  const [options, setOptions] = useState({});
  const [chartThree, setChartThree] = useState({});
  const [chartTwo, setChartTwo] = useState({});
  const [twoCheck, setTwoCheck] = useState(true);
  const chartRef = useRef<any>(null);
  const chartRefOne = useRef<any>(null);
  const chartRefTwo = useRef<any>(null);
  const CHART_TYPES = {
    PRICE: 0,
    COUNTS: 1,
  };
  const formatAbbrThousands = function (value: any) {
    return "#" + (value.value >= 10000 ? `${value.value / 1000}k` : value.value);
  };

  const getXAxisCommon = function () {
    return {
      title: {
        text: undefined,
      },
      type: "datetime",
      gridLineWidth: 1,
      gridZIndex: 2,
      labels: {
        padding: 20,
        style: {
          color: "#333333",
          fontSize: "9px",
          textAlign: "right",
        },
      },

      crosshair: {
        snap: false,
        width: 2,
        zIndex: 15,
        dashStyle: "Dash",
      },
      dateTimeLabelFormats: {
        day: "%e. %b",
        week: "%e. %b",
        month: "%b '%y",
        year: "%Y",
      },
      accessibility: {
        rangeDescription: "Dates",
      },
    };
  };

  document.getElementById("container")?.addEventListener("mouseleave", () => {
    setTimeout(() => {
      Highcharts.each(Highcharts.charts, (chart: any) => {
        if (chart) {
          chart.tooltip.hide();
        }
      });
    }, 1000);
  });
  const formatDateToUTC = (dateString: any) => {
    const date = new Date(dateString);
    return date.getTime();
  };

  function debounce<T extends DebouncedFunction>(func: T, wait: number): T {
    let timeout: NodeJS.Timeout | undefined;

    return function (this: any, ...args: Parameters<T>): void {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => func.apply(this, args), wait);
    } as T;
  }
  const resetPanning = () => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.xAxis[0].setExtremes(null, null);
      }
    }
    if (chartRefOne.current) {
      const chart = chartRefOne.current.chart;
      if (chart) {
        chart.xAxis[0].setExtremes(null, null);
      }
    }
    if (chartRefTwo.current) {
      const chart = chartRefTwo.current.chart;
      if (chart) {
        chart.xAxis[0].setExtremes(null, null);
      }
    }
  };
  const getDateRange = (filterNumber: any, datasets: any) => {
     resetPanning()
    let maxX = 0;
    datasets.forEach((dataset: any) => {
        if (dataset.data?.length) {
           const lastPoint = dataset.data[dataset.data.length - 1];
            maxX = Math.max(maxX, lastPoint[0]); 
        }
    });
    if (!maxX) return { min: null, max: null };
    
    const latestDate = new Date(maxX);
    let minDate = new Date(latestDate);
    
    switch(filterNumber) {
        case 0:
            minDate.setUTCDate(minDate.getUTCDate() - 1);
            break;
        case 1: 
            minDate.setUTCDate(minDate.getUTCDate() - 7);
            break;
        case 2:
            minDate.setUTCMonth(minDate.getUTCMonth() - 1);
            break;
        case 3:
            minDate.setUTCMonth(minDate.getUTCMonth() - 3);
            break;
        case 4:
            minDate.setUTCFullYear(minDate.getUTCFullYear() - 1);
            break;
        case 5:
            return { min: null, max: null };
        default:
            return { min: null, max: null };
    }
    return {
        min: minDate.getTime(),
        max: maxX
    };
};

  useEffect(() => {
    let gChartX = 0;
    const debouncedEventHandler = debounce((e: any) => {
      const container = document.getElementById("container");
      if (!container) return;

      const rect = container.getBoundingClientRect();
      gChartX = e.clientX - rect.left;

      for (const chart of Highcharts.charts) {
        if (chart && !chart.container.contains(e.target)) {
          let event = chart.pointer.normalize(e);
          let points = chart.series.map((series: any) =>
            series.searchPoint(event, true)
          );
          let visiblePoints = points.filter(
            (point: any) => point?.series?.visible
          );
      
          if (visiblePoints.length) {
            chart.tooltip?.refresh(visiblePoints[0]);
            chart.xAxis[0].drawCrosshair(e, visiblePoints[0]);
          }
        }
      }
    }, 0.1);

    ["mousemove", "touchmove", "touchstart"].forEach((eventType) => {
      document
        .getElementById("container")
        ?.addEventListener(eventType, debouncedEventHandler, { passive: true });
    });
    function getCalculatedData(data:any, minTime:any, maxTime:any) {
      if (!data || !Array.isArray(data)) {
          return [];
      }
      if(!minTime || !maxTime) {
        return data;
      }
      const filteredData = data.filter((item: any) => {
          const itemTime = new Date(item[0]).getTime();
          return itemTime >= minTime && itemTime <= maxTime;
      });
      return filteredData;
    }
    let AmazonData = chartsData[0];
    const FBAData = chartsData[1];
    const FBMData = chartsData[2];
    const buyBoxData = chartsData[3];
    const newData = chartsData[4];
    const salesRankData = chartsData[5];
    const monthlySoldData = chartsData[6];
    const offersCountData = chartsData[7];
    const ratingData = chartsData[8];
    const reviewCountData = chartsData[9];
    
    const container = document.getElementById("chart-container");
    const formatAbbrWholeCurrency = function (value: any) {
      return "$" + value.value;
    };

    Highcharts.Pointer.prototype.reset = function () {
      return undefined;
    };
    const SMALLEST_FONT_SIZE = "9px";
    const getHcResponsiveRules = function (aChartType: any) {
      return {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },

            chartOptions: {
              plotOptions: {
                series: {
                  lineWidth: 1,
                },
              },

              legend: {
                itemStyle: {
                  fontSize: SMALLEST_FONT_SIZE,
                },
              },

              chart: {
                marginLeft: 25,
                marginRight: 25,
              },

              yAxis: [
                {
                  labels: {
                    x: -2,
                    y: 10,
                    style: {
                      fontSize: SMALLEST_FONT_SIZE,
                    },
                  },
                },
                {
                  opposite: true,
                  labels: {
                    x: 2,
                    y: 10,
                    style: {
                      fontSize: SMALLEST_FONT_SIZE,
                    },
                  },
                },
                {
                  opposite: true,
                  labels: {
                    x: 2,
                    y: 10,
                    style: {
                      fontSize: SMALLEST_FONT_SIZE,
                    },
                  },
                },
              ],
              xAxis: {
                labels: {
                  style: {
                    fontSize: SMALLEST_FONT_SIZE,
                  },
                },
              },
            },
          },

          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              chart: {},
            },
          },
        ],
      };
    };
    
     
    const activity = {
      xData: Array.from({ length: 500 }, (_, i) => i + 1),
      datasets: [
        {
          name: "Amazon",
          data:
            AmazonData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          type: "area",
          date: AmazonData?.map((item: any) => item?.date) || [],
          valueDecimals: 2,
          unit: "$",
          color: "#F9AF44",
        },
        {
          name: "FBA",
          data:
            FBAData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: FBAData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "$",
          color: "#FF764B",
        },
        {
          name: "FBM",
          data:
            FBMData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: FBMData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "$",
          color: "#22A7E8",
        },
        {
          name: "BuyBox",
          data:
            buyBoxData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: buyBoxData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "$",
          color: "#FF00B4",
        },
        {
          name: "New",
          data:
            newData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: newData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "$",
          color: "#8888DD",
        },
        {
          name: "Sale Rank",
          data:
            salesRankData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: salesRankData?.map((item: any) => item?.date) || [],
          type: "spline",
          valueDecimals: 2,
          unit: "$",
          color: "#9DC59D",
        },
        {
          name: "Monthly Sold",
          data:
            monthlySoldData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: monthlySoldData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "m",
          color: "#D9A420",
        },
        {
          name: "Offer Count",
          data:
            offersCountData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: offersCountData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "m",
          color: "#8888DD",
        },
        {
          name: "Rating",
          data:
            ratingData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: ratingData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "m",
          color: "#3FB0A5",
        },
        {
          name: "Review Count",
          data:
            reviewCountData?.map((item: any) => ({
              x: formatDateToUTC(item?.date),
              y: item?.price,
            })) || [],
          date: reviewCountData?.map((item: any) => item?.date) || [],
          type: "line",
          valueDecimals: 2,
          unit: "m",
          color: "#8AB300",
        },
      ],
    };
    const datasets = activity.datasets.map((dataset: any, i: number) => {
      

      return {
        data: Highcharts.map(dataset?.data, (val: any, j: number) => [
          val.x,
          val.y,
        ]),
        name: dataset.name,
        type: dataset.type,
        color: dataset?.color || "defaultColor",
        fillOpacity: 0.3,
        opacity: 1,
        tooltip: {
          valueSuffix: " " + dataset.unit,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      };
    });
    
    const { min, max  } = getDateRange(filterNumber,datasets);
    const chartDiv1 = document.createElement("div");
    chartDiv1.className = "chart";
    container?.appendChild(chartDiv1);

    const legend = legendStyle || {
      itemStyle: {
        fontSize: "10px",
      },
      enabled: true,
      verticalAlign: "top",
      align: "center",
      borderWidth: 1,
    };

    const getClosestValue = function (
      aData: any,
      aTimestamp: any,
      key?: string
    ) {
      for (let i = aData.length - 1; i > 0; i--) {
        if (aData[i].x <= aTimestamp) {
          return aData[i];
        }
      }

      if (key === "Rating") return aData[aData.length - 1];
      if (key === "Amazon") return aData[aData.length - 1];
      if (key === "Sales Rank") return aData[aData.length - 1];
      return false;
    };
    const hcTooltipFormatter = function (this: any) {
      let tooltip = '<div style="font-size: 8px; padding: 0; width:140px">';
      const formatValue = (lClosest: any, series: any) => {
        let lVal = Highcharts.numberFormat(lClosest.y, 0, ".", ",");
        if (["FBA", "FBM", "Amazon", "Buy Box", "New"].includes(series.name)) {
          lVal = "$" + Highcharts.numberFormat(lClosest.y, 2, ".", ",");
        }
        if (series.name === "Rating") {
          lVal =
            Highcharts.numberFormat(lClosest.y, 1, ".", ",");
        }
        if (series.name === "Monthly Sold") lVal += "+";
        return lVal;
      };
      const createSeriesTooltip = (series: any, lClosest: any) => {
        const lVal = formatValue(lClosest, series);
        return `
          <div style="display: flex; justify-content: space-between; margin: 0px; padding: 0px; line-height: 1">
            <span style="color: ${series.color}; font-size: 9px; font-weight: 500;">
              ${series.name}
            </span>
            <span style="color: ${series.color}; font-size: 9px;">
              ${series.name === "Rating" ? '<span style="color:' + series.color + '">★</span>' : ""}
              <b>${lVal}</b>
            </span>
          </div>
          ${series.name === "Monthly Sold" ? `
            <div style="color: #333333; font-size: 8px;">
              <span style="font-size: 0.5rem; font-weight: 400;color:black !important;opacity:1 !important;">Metric from Amazon search pages</span>
            </div>
          ` : ""}
        `;
      };
      this.points[0].series.chart.series.forEach((series: any) => {
        if (series.visible) {
          const lClosest = getClosestValue(series.data, this.x, series.name);
          if (lClosest?.y != null) {
            tooltip += createSeriesTooltip(series, lClosest);
          }
        }
      });
      tooltip += `
        <div style="text-align: right; margin-top: 5px; color: #333333; font-size: 10px;">
          <small>${Highcharts.dateFormat("%l:%M %a %b %e, %Y", this.x)}</small>
        </div>
      </div>`;
    
      return tooltip;
    };
    

    const getHcTooltip = function (this: any) {
      return {
        shared: true,
        crosshairs: true,
        followPointer: true,
        useHTML: true,
        outside: true,
        positioner: function (this: any, w: any, h: any, aPoint: any) {
          const lcSpacer = 5;
          let lXpos = lcSpacer + gChartX;
          if (lXpos + w > this.chart.plotLeft + this.chart.clipBox.width) {
            lXpos = gChartX - w - lcSpacer;
          }

          return {
            x: Math.min(lXpos, lXpos),
            y: Math.min(
              this.chart.plotTop + aPoint.plotY + 999999,
              this.chart.plotTop + this.chart.clipBox.height - h
            ),
          };
        },

        formatter: hcTooltipFormatter,
      };
    };
    setOptions({
      responsive: { ...getHcResponsiveRules(CHART_TYPES.COUNTS) },
      chart: {
        type: "line",
        panning: {
          enabled: dragActive === 1,
          type: "x",
        },
        panKey: null,
        zoomType: dragActive === 2 ? "x" : null,
        className: 'custom-chart-gap',
        spacing: [10, 0, 10, 0], 
      },
      title: {
        text: "datasets 1-5",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: legend,
      xAxis: {
        ...getXAxisCommon(),
        type: 'datetime',
        min: min,
        max: max,
      },
      yAxis: [
        {
          gridZIndex: 2,
          title: {
            text: undefined,
          },
          labels: {
            x: -2,
            y: 10,
            style: {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "#333333",
              fontSize: "9px",
              textAlign: "left",
            },
            format: "{value}",
            formatter: formatAbbrWholeCurrency,
          },
        },
        {
          gridZIndex: 2,
          title: {
            text: undefined,
          },
          reversed: false,
          opposite: true,
          labels: {
            x: 2,
            y: 10,
            style: {
              color: "#333333",
              fontSize: "9px",
              textAlign: "right",
            },
            formatter: formatAbbrThousands,
          },
        },
      ],
      tooltip: getHcTooltip(),
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          lineWidth: 1.5,
          step: "left",
          opacity: 1,
          fillOpacity: 0.3,
          events: {
            mouseOut: function (this: any) {
              this.update({
                marker: {
                  enabled: false,
                },
              });
            },
          },
        },
        area: {
          fillOpacity: 0.3,
        },
      },
      series: [
        {
          type: "area",
          name: "Amazon",
          data: datasets[0].data,
          visible: true,
          custom: {
            type: "money",
          },

          color: "#FF9900",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "#FEE2C5"],
              [1, "#FEEFE0"],
            ],
          },

          zIndex: 1,

          fillOpacity: 0.2,
          marker: {
            enabled: false,
          },
          yAxis: 0,
        },
        {
          name: "FBA",
          data: datasets[1].data,
          color: "#ff5722",
          custom: {
            type: "money",
          },

          marker: {
            enabled: false,
            fillColor: "#ffffff00",
            symbol: "triangle",
          },
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
          zIndex: 5,
          yAxis: 0,
        },

        {
          name: "FBM",
          data:getCalculatedData(datasets[2].data,min,max),
          visible: true,
          custom: {
            type: "money",
          },

          color: "#039be5",
          marker: {
            enabled: false,
            lineColor: null,
            fillColor: "#ffffff00",
            symbol: "square",
          },
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
          zIndex: 5,
        },

        {
          name: "Buy Box",
          data: datasets[3].data,
          color: "#ff00b4",
          custom: {
            type: "money",
          },
          marker: {
            enabled: false,
          },

          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
          zIndex: 5,
        },
        {
          name: "New",
          data: datasets[4].data,
          color: "#8888DD",
          visible: true,
          custom: {
            type: "money",
          },

          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
          zIndex: 5,
        },
        {
          name: "Sales Rank ",
          yAxis: 1,
          visible: true,
          data: datasets[5].data,
          color: "#8fbc8f",
          marker: {
            enabled: false,
          },
          zIndex: 1,
        },
      ],
    });
    if (datasets[6].data.length) {
      setTwoCheck(true);
    } else {
      setTwoCheck(false);
    }

    setChartTwo({
      responsive: { ...getHcResponsiveRules(CHART_TYPES.COUNTS) },
      chart: {
        type: "line",
        spacingTop: 20,
        panning: {
          enabled: dragActive === 1,
          type: "x",
        },
        panKey: null,
        zoomType: dragActive === 2 ? "x" : null,
      },
      title: {
        text: "Dataset 6",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: legend,
      xAxis: {
        ...getXAxisCommon(),
        type: 'datetime', // Ensure type is datetime
        min: min,
        max: max,
      },
      yAxis: [
        {
          style: {
            width: "40px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          gridZIndex: 2,
          allowDecimals: false,
          reversed: false,
          title: {
            text: undefined,
          },
          labels: {
            x: -2,
            y: 10,
            enabled: true,
            formatter: formatAbbrThousands,
            style: {
              color: "#333333",
              fontSize: "9px",
              textAlign: "right",
            },
          },
        },
        {
          title: {
            text: "Sold in past month",
            style: {
              color: "#333333",
              fontSize: "8px",
              textAlign: "right",
            },
          },
          opposite: true,
        },
      ],
      tooltip: getHcTooltip(),
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          marker: {
            enabled: false,
            symbol: "square",
            radius: 4,
          },
          lineWidth: 1.5,
          step: "left",
          opacity: 1,
          fillOpacity: 0.3,
          events: {
            mouseOut: function (this: any) {
              this.update({
                marker: {
                  enabled: false,
                },
              });
            },
          },
        },
        area: {
          fillOpacity: 0.3,
        },
      },
      series: [
        {
          name: "Monthly Sold",
          data: datasets[6].data,
          color: "#DAA520",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          custom: {
            type: "monthly_sold",
          },
          zIndex: 5,
          yAxis: 0,
        },
      ],
    });

    setChartThree({
      responsive: { ...getHcResponsiveRules(CHART_TYPES.COUNTS) },
      chart: {
        type: "line",
        spacingTop: 20,
        panning: {
          enabled: dragActive === 1,
          type: "x",
        },
        panKey: null,
        zoomType: dragActive === 2 ? "x" : null,
        spacing: [10, 0, 10, 0],
        margin: [70, 23, 70, 10],
      },
      title: {
        text: "Combined Datasets 7-9",
        align: "left",
        margin: 0,
        x: 30,
      },
      credits: {
        enabled: false,
      },
      legend: legend,
      xAxis: {
        ...getXAxisCommon(),
        type: 'datetime',
        min: min,
        max: max,
      },
      yAxis: [
        {
          style: {
            width: "40px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          gridZIndex: 2,
          allowDecimals: false,
          reversed: false,
          title: {
            text: undefined,
          },
          labels: {
            x: -2,
            y: 10,
            format: "{value}",
            style: {
              color: "#333333",
              fontSize: "9px",
              textAlign: "left",
            },
          },
        },
        {
          visible: false,
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
          title: {
            text: undefined,
          },
          labels: {
            format: "{value}*",
            style: {
              color: "#333333",
            },
          },
          max: 5,
          softMin: 1,
          tickPositions: [1, 2, 3, 4, 5],
        },
        {
          reversed: false,
          opposite: true,
          title: {
            text: undefined,
          },
          labels: {
            x: 2,
            y: 10,
            style: {
              color: "#333333",
              fontSize: "9px",
              textAlign: "right",
            },
            formatter: formatAbbrThousands,
          },
        },
      ],
      tooltip: getHcTooltip(),
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          marker: {
            enabled: false,
            symbol: "square",
            radius: 4,
          },
          lineWidth: 1.5,
          step: "left",
          opacity: 1,
          fillOpacity: 0.3,
          events: {
            mouseOut: function (this: any) {
              this.update({
                marker: {
                  enabled: false,
                },
              });
            },
          },
        },
        area: {
          fillOpacity: 0.3,
        },
      },
      series: [
        {
          name: "Offer Count",
          data: datasets[7].data,
          color: "#8888dd",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          zIndex: 5,
          yAxis: 0,
        },
        {
          name: "Rating",
          yAxis: 1,
          data: datasets[8].data,
          color: "#009688",
          lineWidth: 1,
          custom: {
            type: "rating",
          },
          marker: {
            enabled: false,
          },
          zIndex: 5,
        },
        {
          name: "Review Count",
          yAxis: 2,
          data: datasets[9].data,
          color: "#8ab300",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          zIndex: 15,
        },
      ],
    });
    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [chartsData, CHART_TYPES.COUNTS, legendStyle, dragActive,filterNumber]);

  return (
    <div id="container" className="p-0" style={{ width: "100%", height: "600px !important" }}>
      <div id="chartss" className="p-0">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
          containerProps={{ style: { height: "310px"} }}
        />
        {twoCheck && (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartTwo}
            ref={chartRefOne}
            containerProps={{ style: { height: "180px" } }}
          />
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={chartThree}
          ref={chartRefTwo}
          containerProps={{ style: { height: "240px" } }}
        />
      </div>
    </div>
  );
};

export default ChartDemo;
