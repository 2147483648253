import React, { useState } from "react";
import { handleValues,useStyles } from "../../../helpers";
import { Tooltip } from "@mui/material";
interface Discount {
  type: string;
  title: string;
  value: string;
}

interface DiscountsProps {
  data: Discount[];
  productDetails: any;
  setProductDetails: any;
  setTotalDiscount: any;
}

const Discounts = ({
  data,
  productDetails,
  setProductDetails,
  setTotalDiscount,
}: DiscountsProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const classes = useStyles();
  const handleClick = (index: number) => {
    const selectedIndex = selectedIndexes.indexOf(index);
    let updatedIndexes;

    if (selectedIndex === -1) {
      updatedIndexes = [...selectedIndexes, index];
    } else {
      updatedIndexes = [...selectedIndexes];
      updatedIndexes.splice(selectedIndex, 1);
    }

    setSelectedIndexes(updatedIndexes);

    calculateTotalDiscount(updatedIndexes)
      .then((totalDiscount) => {
        setTotalDiscount(totalDiscount);
        const updatedValue = handleValues(
          productDetails,
          "quickInfo",
          totalDiscount
        );
        setProductDetails(updatedValue);
      })
      .catch((error) => {
        console.error("Error calculating total discount:", error);
      });
  };

  const calculateTotalDiscount = async (indexes: number[]) => {
    let totalDiscount = 0;
    indexes.forEach((index) => {
      if (data[index].title === "3 for 2") {
        totalDiscount += 33.34;
      } else {
        totalDiscount += parseFloat(data[index].title);
      }
    });

    return totalDiscount?.toFixed(2);
  };

  return (
    <div id="discounts">
      <div className="offers-contentsss d-flex gap-2 flex-wrap">
        {data?.map((item: Discount, index: number) => (
          <Tooltip
            key={`${item}-${index}`}
            title={<div className="content-tooltip">{item.title}</div>}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <button
              type="button"
              className={`discount-btn-refresh ${
                selectedIndexes.includes(index)
                  ? "btn-refresh"
                  : "discount-btn-refresh"
              }`}
              onClick={() => handleClick(index)}
            >
              {item.title}
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default Discounts;
