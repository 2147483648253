import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const PasswordReset = ({
  showModal,
  modelClose,
  oldPassword,
  confirmPassword,
  loading,
  newPassword,
  errors,
  handleUpdate,
  handleInputChange,
}: any) => {
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field: string) => {
    setShowPasswords((prev: any) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Password Reset
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={20}
            onClick={modelClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex gap-1 px-2 py-2">
            <Col lg={12} md={12} xs={12} className="mt-2">
              <Form.Group>
                <Form.Label>Old Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPasswords.oldPassword ? "text" : "password"}
                    placeholder="Enter Old Password"
                    value={oldPassword}
                    onChange={(e) =>
                      handleInputChange("oldPassword", e.target.value)
                    }
                    isInvalid={!!errors.oldPassword}
                  />
                  <button
                    type="button"
                    className="position-absolute end-0 top-50 translate-middle-y pe-2"
                    style={{ cursor: "pointer", border: "none" }}
                    onClick={() => togglePasswordVisibility("oldPassword")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        togglePasswordVisibility("oldPassword");
                      }
                    }}
                    aria-label={
                      showPasswords.oldPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    {!errors.oldPassword &&
                      (showPasswords.oldPassword ? (
                        <FaEyeSlash size={16} />
                      ) : (
                        <FaEye size={16} />
                      ))}
                  </button>

                  <Form.Control.Feedback type="invalid">
                    {errors.oldPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col lg={12} md={12} xs={12} className="mt-2">
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPasswords.newPassword ? "text" : "password"}
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) =>
                      handleInputChange("newPassword", e.target.value)
                    }
                    isInvalid={!!errors.newPassword}
                  />
                  <button
                    type="button"
                    className="position-absolute end-0 top-50 translate-middle-y pe-2"
                    style={{ cursor: "pointer", border: "none" }}
                    onClick={() => togglePasswordVisibility("newPassword")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        togglePasswordVisibility("newPassword");
                      }
                    }}
                    aria-label={
                      showPasswords.newPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    {!errors.newPassword &&
                      (showPasswords.newPassword ? (
                        <FaEyeSlash size={16} />
                      ) : (
                        <FaEye size={16} />
                      ))}
                  </button>

                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
            <Col lg={12} md={12} xs={12} className="mt-2">
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPasswords.confirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) =>
                      handleInputChange("confirmPassword", e.target.value)
                    }
                    isInvalid={!!errors.confirmPassword}
                  />
                  <button
                    type="button"
                    className="position-absolute end-0 top-50 translate-middle-y pe-2"
                    style={{ cursor: "pointer", border: "none" }}
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        togglePasswordVisibility("confirmPassword");
                      }
                    }}
                    aria-label={
                      showPasswords.confirmPassword
                        ? "Hide password"
                        : "Show password"
                    }
                  >
                    {!errors.confirmPassword &&
                      (showPasswords.confirmPassword ? (
                        <FaEyeSlash size={16} />
                      ) : (
                        <FaEye size={16} />
                      ))}
                  </button>

                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modelClose} disabled={loading}>
            Close
          </Button>
          <Button onClick={handleUpdate} disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PasswordReset;
