import React, { useContext, useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Button, Input, Modal, Popconfirm, message } from "antd";
import { IoMdAdd } from "react-icons/io";
import { HiDownload } from "react-icons/hi";
import { IoIosInformationCircleOutline, IoIosStar } from "react-icons/io";
import { MdOutlineBarChart } from "react-icons/md";
import { FaAmazon, FaRegImage } from "react-icons/fa6";
import { Tooltip } from "antd";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { FaSearch } from "react-icons/fa";

// AG Grid imports
import { AgGridReact } from "ag-grid-react";

import {
  ClientSideRowModelModule,
  ValidationModule,
  RowSelectionModule,
  NumberFilterModule,
  TextFilterModule,
  DateFilterModule,
  IDateFilterParams,
  ModuleRegistry,
  themeAlpine,
  TooltipModule,
} from "ag-grid-community";

import {
  AllEnterpriseModule,
  LicenseManager,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  PivotModule,
  SideBarModule,
} from "ag-grid-enterprise";

import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import {
  addFavListProduct,
  getFavListSource,
  getGraphDetails,
  postFavListSource,
  productDetails,
  token,
  FavListIsExists,
  productUpdate,
  getPresets,
  createPreset,
  Preset,
  getPresetById,
  scanSettings,
  shippingSettings,
  scanSettingsUpdate,
  shippingSettingsUpdate,
} from "../../Service/services";

import { SearchContext } from "../../context/searchContext";
import Loader from "../Loader";
import OverviewModal from "./overviewModal";
import ScanSettingModal from "./scanSettingModal";
import ImageTooltip from "./imageTooltip";
import CopyText from "../CopyText";

import "./index.css";
import toast from "react-hot-toast";
import ScanNewModal from "./scanNewModal";
import KeepaChartTooltip from "./keepaChartTooltip";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ValidationModule,
  RowSelectionModule,
  NumberFilterModule,
  TextFilterModule,
  DateFilterModule,
  AllEnterpriseModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  PivotModule,
  SideBarModule,
  TooltipModule,
]);

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-076059}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SellerScout}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SellerScout}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SellerScout}_need_to_be_licensed___{SellerScout}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_January_2026}____[v3]_[01]_MTc2OTczMTIwMDAwMA==a736b450a386f0962e8a6edb3e3d21bf`
);
interface RowData {
  id: number;
  from: string;
  to: string;
  price: string;
}
const ParticularItem: React.FunctionComponent = () => {
  const userParams = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userProfile") || "{}");

  const [isRow, setIsRow] = useState<any[]>([]);
  const [isResponse, setIsResponse] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("FBA");
  const [isCurrentPage, setIsCurrentPage] = useState<any>(1);
  const [isExportLoading, setIsExportLoading] = useState(false);

  // Favorites
  const [islist, setIsList] = useState<any>({});
  const [isFavList, setIsFavList] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInput, setIsInput] = useState<string>("");

  const [isExport, setIsExport] = useState<any>(null);
  const [isCompressChecked, setIsCompressChecked] = useState<boolean>(false);
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState<boolean>(true);

  // Graph & images
  const [isGraph, setIsGraph] = useState<any>(null);
  const [isGraphLoading, setIsGraphLoading] = useState<boolean>(true);
  const [hoveredImage, setHoveredImage] = useState<any>(null);
  const [keepaImageCheck, setKeepaImageCheck] = useState<boolean>(false);

  // Modals
  const [show, setShow] = useState(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowNewModal, setIsShowNewModal] = useState<boolean>(false);
  //Presets
  const [presets, setPresets] = useState<Preset[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<string>("");
  const [presetName, setPresetName] = useState<string>("");
  const [isPresetModalOpen, setIsPresetModalOpen] = useState<boolean>(false);

  const [gridApi, setGridApi] = useState<any>(null);

  const [shippingSettingsError, setShippingSettingsError] =
    useState<boolean>(false);
  const [shippingRoleError, setShippingRoleError] = useState<boolean>(false);
  const [rows, setRows] = useState<RowData[]>([
    { id: Date.now(), from: "", to: "", price: "" },
  ]);
  const [isPrepFee, setIsPrepFee] = useState<any>(0);
  const [isAmazonCheck, setIsAmazonCheck] = useState<any>("");
  const [isKeepaChartDataRange, setIsKeepaChartDataRange] = useState<any>("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [shippingCostValue, setShippingCostValue] = useState<any>("");
  const [scanSettingLoading, setScanSettingLoading] = useState<boolean>(false);
  const [shippingSettingsLoading, setShippingSettingsLoading] =
    useState<boolean>(false);
  const onGridReady = (params: any): void => {
    setGridApi(params.api);
  };

  const fetchPresetsFromAPI = async () => {
    try {
      const data = await getPresets();
      setPresets(data);
    } catch (error) {
      console.error("Error fetching presets:", error);
    }
  };

  useEffect(() => {
    fetchPresetsFromAPI();
  }, []);

  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error(
      "SearchContext is undefined. Ensure the component is within SearchState."
    );
  }

  const { searchValue, setSearchValue, isGobalFilter, setIsGobalFilter } =
    searchContext;

  const Token = localStorage.getItem("accessToken");

  const handleAmazonClick = (asin: any) => {
    window.open(`https://www.amazon.com/dp/${asin}`, "_blank");
  };

  const filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;
      if (dateAsString == null) return -1;
      const dateParts = dateAsString.split("/");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const handleMagnifierClick = (params: any) => {
    console.log("params:", params);
    console.log("params.data:", params.data);
    console.log("params.data.asin:", params.data?.asin);

    if (!params.data || !params.data.asin) {
      console.error("ASIN is missing or undefined", params);
      return;
    }

    setSearchValue(params.data.asin);
    setShow(true);
  };

  const myTheme = themeAlpine.withParams({
    accentColor: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    browserColorScheme: "inherit",
    fontSize: 14,
    headerFontFamily: { googleFont: "Roboto" },
    headerFontSize: 12,
    headerVerticalPaddingScale: 1.5,
    rowVerticalPaddingScale: 1,
  });
  const powerUserCheck = (
    userData: any,
    userParams: any,
    paramsValue: any,
    key: string
  ) => {
    const isPowerUser = userData?.isPowerUser;
    const isPowerUpDisabled = userParams?.enablePowerUpData === "false";

    if (!isPowerUser || (isPowerUser && isPowerUpDisabled)) {
      const tooltipTitle = !isPowerUser
        ? "Upgrade to a paid plan to unlock Power Up feature"
        : "To show this data, enable PowerUp data features on Add Scan Screen.";

      return (
        <Tooltip placement="top" title={tooltipTitle}>
          <IconButton>
            <IoIosInformationCircleOutline
              className="info-icon"
              size={25}
              data-tooltip-id="my-tooltip-2"
            />
          </IconButton>
        </Tooltip>
      );
    }

    const numberKeys = [
      "rating",
      "reviews",
      "salesRankDrops30",
      "salesRankDrops90",
      "salesRankDrops180",
      "salesRankDrops365",
      "lowestBSR30",
      "lowestBSR90",
      "lowestBSR180",
    ];
    const percentageKeys = [
      "amazonInStockRate30",
      "amazonInStockRate90",
      "amazonInStockRate180",
    ];

    if (paramsValue !== undefined && paramsValue !== null) {
      if (numberKeys.includes(key))
        return <CopyText value={paramsValue} show={true} />;
      if (key === "isSoldByAmazon") return paramsValue;
      if (percentageKeys.includes(key))
        return <CopyText value={`${paramsValue}%`} show={true} />;
      return (
        <CopyText
          value={
            paramsValue
              ? paramsValue < 0
                ? `- $${Math.abs(paramsValue).toFixed(2)}`
                : `$${paramsValue.toFixed(2)}`
              : "$0.00"
          }
          show={true}
        />
      );
    }

    const defaultValues: { [key: string]: string } = {
      isSoldByAmazon: "-",
      ...Object.fromEntries(numberKeys.map((k) => [k, "0"])),
      ...Object.fromEntries(percentageKeys.map((k) => [k, "0%"])),
    };

    return defaultValues[key] ?? "$0.00";
  };
  // AG Grid Column Definitions
  const [columnDefs] = useState<any[]>([
    {
      headerName: "",
      field: "imageURL",
      pinned: "left",
      width: 20,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      cellRenderer: (params: any) => (
        <div
          style={{
            display: "flex",
            marginTop: "5px",
            alignItems: "center",
            justifyContent: "center",
            color: "#c8c8c8",
          }}
        >
          <Tooltip title="View Details" arrow>
            <IconButton
              onClick={() => handleMagnifierClick(params)}
              style={{ cursor: "pointer", fontSize: "15px" }}
            >
              <FaSearch />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      headerName: "Actions",
      field: "imageURL",
      pinned: "left",
      width: 160,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      cellRenderer: (params: any) => (
        <div
          style={{
            display: "flex",
            marginTop: "12px",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Tooltip title="Add to Favorites">
            <IoIosStar
              style={{
                cursor: "pointer",
                color: params.data.haveInFavorite ? "gold" : "gray",
                fontSize: "15px",
              }}
            />
          </Tooltip>

          <Tooltip title="View Image">
            <FaRegImage
              style={{ cursor: "pointer", fontSize: "15px" }}
              onMouseEnter={() => handleMouseEnter(params.data.imageURL)}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>

          <Tooltip title="View on Amazon">
            <FaAmazon
              style={{ cursor: "pointer", fontSize: "15px" }}
              onClick={() => handleAmazonClick(params.data.asin)}
            />
          </Tooltip>

            <MdOutlineBarChart
              style={{ cursor: "pointer", fontSize: "15px" }}
              onMouseEnter={() => handleChartIconHover(params)}
              onMouseLeave={handleMouseLeave}
            />
        </div>
      ),
    },
    {
      headerName: "Search Identifier",
      field: "productSearch",
      width: 140,
      cellRenderer: (params: any) => (
        <div>
          <CopyText value={params.data.productSearch} show={true} />
        </div>
      ),
    },
    {
      headerName: "ASIN",
      field: "asin",
      width: 140,
      cellRenderer: (params: any) => (
        <div>
          <CopyText value={params.data.asin} show={true} />
        </div>
      ),
    },
    {
      headerName: "UPC",
      field: "upc",
      filter: "agNumberColumnFilter",
      width: 140,
      cellRenderer: (params: any) => {
        if (!params.value) {
          return <div className="centered-cell">-</div>;
        }
        const padded = String(params.value).padStart(12, "0");
        return (
          <div className="centered-cell">
            <CopyText value={padded} show={true} />
          </div>
        );
      },
    },
    {
      headerName: "EAN",
      field: "ean",
      width: 190,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        if (!params.value) {
          return <div className="centered-cell">-</div>;
        }
        const padded = String(params.value).padStart(14, "0");
        return (
          <div className="centered-cell">
            <CopyText value={padded} show={true} />
          </div>
        );
      },
    },
    {
      headerName: "ISBN",
      field: "isbn",
      width: 130,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={String(params.value)} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "Title",
      field: "title",
      width: 450,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => (
        <Tooltip title={params.value}>
          <span
            style={{
              cursor: "pointer",
              color: "black",
            }}
            onClick={() => handleAmazonClick(params.data.asin)}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },

    {
      headerName: "Est Sales",
      field: "estimatedSales",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        const val = params.value;
        if (val !== null && val <= 5) {
          return (
            <div className="centered-cell">
              <span className="price-drop-icon">{"<"}</span>
              <CopyText value={val} show={true} />
            </div>
          );
        } else {
          return (
            <div className="centered-cell">
              <CopyText value={val} show={true} />
            </div>
          );
        }
      },
    },
    {
      headerName: "Profit",
      field: "profit",
      width: 110,
      filter: "agNumberColumnFilter",
      tooltipValueGetter: (params: any) => {
        const data = params.data;
        return `
          Profit Calculation:\n
          \nEstimated Selling Price: $${data.price || "0.00"}
          \nAmazon Referral Fee: -$${data.referralFee?.toFixed(2) || "0.00"}
          \nFBA Fee: -$${data.fbaFee?.toFixed(2) || "0.00"}
          \nVariable Closing Fee: -$${
            data.variableClosingFee?.toFixed(2) || "0.00"
          }
          \nInbound Placement Fee: -$${
            data.inboundPlacementFee?.toFixed(2) || "0.00"
          }
          \nInbound Shipping: ${data.weight || "0.00"} lb * $${
          data.shippingCostPerLb || "0.00"
        } per lb = -$${
          (data.weight * data.shippingCostPerLb)?.toFixed(2) || "0.00"
        }
          \nVAT: -$${data.vat?.toFixed(2) || "0.00"}
          \nCost * Qty: -($${data.cost?.toFixed(2) || "0.00"} * ${
          data.qty || "1"
        }) = -$${(data.cost * data.qty)?.toFixed(2) || "0.00"}
          \nTax * Qty: -($${data.tax?.toFixed(2) || "0.00"} * ${
          data.qty || "1"
        }) = -$${(data.tax * data.qty)?.toFixed(2) || "0.00"}
          \nPrep Cost Per Unit * Qty: -($${
            data.prepCost?.toFixed(2) || "0.00"
          } * ${data.qty || "1"}) = -$${
          (data.prepCost * data.qty)?.toFixed(2) || "0.00"
        }
          \nProfit: $${params.value?.toFixed(2) || "0.00"}
        `;
      },
      cellStyle: (params: any) => {
        return params.value < 0
          ? { color: "#f56565", textAlign: "center" }
          : { color: "#000", textAlign: "center" };
      },
    },
    {
      headerName: "ROI",
      field: "roi",
      width: 150,
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => {
        const val = params.value;
        return <div className="centered-cell">{val ? `${val}%` : "0.00%"}</div>;
      },
      cellStyle: (params: any) => {
        return params.value < 0
          ? { color: "#f56565", textAlign: "center" }
          : { color: "#000", textAlign: "center" };
      },
    },
    {
      headerName: "Margin",
      field: "margin",
      width: 120,
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => {
        const val = params.value;
        return <div className="centered-cell">{val ? `${val}%` : "0.00%"}</div>;
      },
      cellStyle: (params: any) => {
        return params.value < 0
          ? { color: "#f56565", textAlign: "center" }
          : { color: "#000", textAlign: "center" };
      },
    },
    {
      headerName: "Sales Rank",
      field: "salesRank",
      width: 150,
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => (
        <CopyText value={params.value} show={true} />
      ),
    },
    {
      headerName: "Product Group",
      field: "productGroup",
      width: 150,
      filter: "agTextColumnFilter",
      cellStyle: { textAlign: "left" },
      cellRenderer: (params: any) => (
        <div className="left-cell">{params.value ? params.value : "-"}</div>
      ),
    },
    {
      headerName: "Cost",
      field: "cost",
      width: 150,
      filter: "agNumberColumnFilter",
      cellClass: "custom-cell",
      editable: true,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText
            value={params.value ? `$${params.value}` : "$0.00"}
            show={true}
          />
        </div>
      ),
    },
    {
      headerName: "Cost Sub Total",
      field: "costSubTotal",
      width: 150,
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText
            value={params.value ? `$${params.value}` : "$0.00"}
            show={true}
          />
        </div>
      ),
    },
    {
      headerName: "Margin Impact",
      field: "marginImpact",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => (
        <div className="centered-cell param-value">
          <CopyText
            value={params.value ? `$${params.value}` : "$0.00"}
            show={true}
          />
        </div>
      ),
    },
    {
      headerName: "Price",
      field: "price",
      width: 150,
      filter: "agNumberColumnFilter",
      editable: true,
      cellClass: "custom-cell",
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) =>
        params.value ? (
          <span>${params.value}</span>
        ) : (
          <Tooltip title="Price Unavailable. Product not currently in stock or has multiple variations with varying prices.">
            <IoIosInformationCircleOutline className="info-icon" size={20} />
          </Tooltip>
        ),
    },
    {
      headerName: "Model",
      field: "model",
      width: 150,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => (
        <CopyText value={params.value} show={true} />
      ),
    },
    {
      headerName: "Manufacturer",
      field: "manufacturer",
      width: 150,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => (
        <CopyText value={params.value} show={true} />
      ),
    },
    {
      headerName: "Part Number",
      field: "partNumber",
      width: 150,
      cellStyle: { textAlign: "center" },
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => (
        <CopyText value={params.value} show={true} />
      ),
    },
    {
      headerName: "Vat",
      field: "vat",
      width: 150,
      cellStyle: { textAlign: "center" },
      filter: "agNumberColumnFilter",
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "-"}</div>
      ),
    },
    {
      headerName: "Vat Percentage",
      field: "vatPercentage",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        const val = params.value;
        return <div className="centered-cell">{val ? `${val}%` : "0%"}</div>;
      },
    },
    {
      headerName: "Vat Amount",
      field: "vatAmount",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        const val = params.value;
        return <div className="centered-cell">{val ? `$${val}` : "$0.00"}</div>;
      },
    },
    {
      headerName: "Package Quantity",
      field: "packageQuantity",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      editable: true,
      cellClass: "custom-cell",
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText value={params.value ? params.value : "-"} show={true} />
        </div>
      ),
    },
    {
      headerName: "Item Height",
      field: "itemHeight",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: (params: any) => {
        const val = params.value;
        const numericVal =
          val !== undefined && val !== null && !isNaN(Number(val))
            ? Number(val).toFixed(2)
            : "-";
        return <CopyText value={numericVal} show={true} />;
      },
    },
    {
      headerName: "Item Length",
      field: "itemLength",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        const val = params.value;
        const numericVal =
          val !== undefined && val !== null && !isNaN(Number(val))
            ? Number(val).toFixed(2)
            : "-";
        return <CopyText value={numericVal} show={true} />;
      },
    },
    {
      headerName: "Item Width",
      field: "itemWidth",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        const val = params.value;
        const numericVal =
          val !== undefined && val !== null && !isNaN(Number(val))
            ? Number(val).toFixed(2)
            : "-";
        return <CopyText value={numericVal} show={true} />;
      },
    },
    {
      headerName: "Item Weight",
      field: "itemWeight",
      filter: "agNumberColumnFilter",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => {
        const val = params.value;
        const numericVal =
          val !== undefined && val !== null && !isNaN(Number(val))
            ? Number(val).toFixed(2)
            : "-";
        return <CopyText value={numericVal} show={true} />;
      },
    },
    {
      headerName: "Package Dimension",
      field: "packageDim",
      filter: "agNumberColumnFilter",
      width: 170,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText value={params.value ? params.value : "-"} show={true} />
        </div>
      ),
    },
    {
      headerName: "BuyBox Price",
      field: "buyBoxLanded",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        const val = params.value;
        return (
          <div className="centered-cell">
            {val ? <CopyText value={`$${val}`} show={true} /> : "-"}
          </div>
        );
      },
    },
    {
      headerName: "BuyBox Contention",
      field: "buyBoxContention",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        const val = params.value;
        return val ? `${val} sellers` : "-";
      },
    },
    {
      headerName: "Avg BuyBoxPrice 30d",
      field: "averageBuyBoxPrice30",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) =>
        userData && !userData.isPowerUser ? (
          <Tooltip title="Upgrade to a paid plan to unlock Power Up feature">
            <IconButton>
              <IoIosInformationCircleOutline className="info-icon" size={25} />
            </IconButton>
          </Tooltip>
        ) : params.value ? (
          <CopyText value={`$${params.value}`} show={true} />
        ) : (
          "-"
        ),
    },
    {
      headerName: "Avg BuyBoxPrice 90d",
      field: "averageBuyBoxPrice90",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Avg BuyBoxPrice 180d",
      field: "averageBuyBoxPrice180",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 190,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Rating",
      field: "rating",
      filter: "agNumberColumnFilter",
      width: 120,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={String(params.value)} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Reviews",
      field: "reviews",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={String(params.value)} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Variation Parent",
      field: "variationParent",
      filter: "agTextColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <div className="centered-cell">
            {params.value ? params.value : "-"}
          </div>
        );
      },
    },
    {
      headerName: "Parent ASIN",
      field: "parentASIN",
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "-"}</div>
      ),
    },
    {
      headerName: "Variation Count",
      field: "variationCount",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "0"}</div>
      ),
    },
    {
      headerName: "Offers",
      field: "offers",
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "0"}</div>
      ),
    },
    {
      headerName: "Shipping Cost",
      field: "shippingCost",
      filter: "agNumberColumnFilter",
      width: 150,
      cellClass: "custom-cell",
      editable: true,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "Color",
      field: "color",
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText value={params.value ? params.value : "-"} show={true} />
        </div>
      ),
    },
    {
      headerName: "Size",
      field: "size",
      filter: "agTextColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 160,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "FBA Fee",
      field: "fbaFee",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$ ${params.value.toFixed(2)}`} show={true} />
          ) : (
            "$ 0.00"
          )}
        </div>
      ),
    },
    {
      headerName: "Referral Fee",
      field: "referralFee",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$ ${params.value.toFixed(2)}`} show={true} />
          ) : (
            "$ 0.00"
          )}
        </div>
      ),
    },
    {
      headerName: "Variable Closing Fee",
      field: "variableClosingFee",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 170,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$ ${params.value.toFixed(2)}`} show={true} />
          ) : (
            "$ 0.00"
          )}
        </div>
      ),
    },
    {
      headerName: "Amazon Last Seen",
      field: "amazonLastSeen",
      filter: "agDateColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 200,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          <CopyText value={params.value ? params.value : "-"} show={true} />
        </div>
      ),
    },
    {
      headerName: "Sold By Amazon",
      field: "isSoldByAmazon",
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Sales Rank Drops 90",
      field: "salesRankDrops90",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? String(params.value) : "-";
        }
      },
    },
    {
      headerName: "Sales Rank Drops 30",
      field: "salesRankDrops30",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Sales Rank Drops 180",
      field: "salesRankDrops180",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },

    {
      headerName: "Sales Rank Drops 365",
      field: "salesRankDrops365",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 180,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Average BSR 30",
      field: "averageBSR30",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },

    {
      headerName: "Average BSR 90",
      field: "averageBSR90",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Average BSR 180",
      field: "averageBSR180",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 170,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Amazon In Stock Rate 30",
      field: "amazonInStockRate30",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 200,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? `${params.value}%` : "-";
        }
      },
    },
    {
      headerName: "Amazon In Stock Rate 90",
      field: "amazonInStockRate90",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 200,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Amazon In Stock Rate 180",
      field: "amazonInStockRate180",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 200,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? `${params.value}%` : "-";
        }
      },
    },
    {
      headerName: "Lowest BSR 30",
      field: "lowestBSR30",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      // CHANGED: no "$"
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? String(params.value) : "-";
        }
      },
    },
    {
      headerName: "Lowest BSR 90",
      field: "lowestBSR90",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },

    {
      headerName: "Lowest BSR 180",
      field: "lowestBSR180",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => {
        if (userData && !userData.isPowerUser) {
          return (
            <Tooltip
              title="Upgrade to a paid plan to unlock Power Up feature"
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else if (
          userData &&
          userData.isPowerUser &&
          userParams &&
          userParams.enablePowerUpData === "false"
        ) {
          return (
            <Tooltip
              title="To show this data, enable PowerUp data features on Add Scan Screen."
              placement="top"
            >
              <IconButton>
                <IoIosInformationCircleOutline
                  className="info-icon"
                  size={25}
                />
              </IconButton>
            </Tooltip>
          );
        } else {
          return params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          );
        }
      },
    },
    {
      headerName: "Payout",
      field: "payout",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$${params.value}`} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "Package Weight",
      field: "packageWeighti",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "-"}</div>
      ),
    },
    {
      headerName: "Lowest FBA",
      field: "LowestFBA",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">{params.value ? params.value : "-"}</div>
      ),
    },
    {
      headerName: "Lowest FBM",
      field: "lowestFBM",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 150,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "Inbound Shipping Estimate",
      field: "inboundShippingEstimate",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center" },
      width: 220,
      cellRenderer: (params: any) => (
        <div className="centered-cell">
          {params.value ? (
            <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      headerName: "New FBA Offers",
      field: "fbaOffers",
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <div className="centered-cell">
            {params.value ? (
              <CopyText value={`$${params.value.toFixed(2)}`} show={true} />
            ) : (
              "0.00"
            )}
          </div>
        );
      },
    },
    {
      headerName: "New FBM Offers",
      field: "fbmOffers",
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <div className="centered-cell">
            {params.value ? String(params.value) : "0"}
          </div>
        );
      },
    },
    {
      headerName: "Is Top Level Category",
      field: "isRootCategory",
      width: 160,
      cellRenderer: (params: any) => {
        return params.value ? "Check" : "X";
      },
    },
    {
      headerName: "Brand Alerts",
      field: "isBrandAlert",
      width: 110,
      cellRenderer: (params: any) => {
        return params.value ? "Check" : "X";
      },
    },
    {
      headerName: "Is Hazmat",
      field: "isHazmat",
      width: 110,
      cellRenderer: (params: any) => {
        return params.value ? "Check" : "X";
      },
    },
    {
      headerName: "Is Alcoholic",
      field: "isAlcoholic",
      width: 130,
      cellRenderer: (params: any) => {
        return params.value ? "Check" : "X";
      },
    },
    {
      headerName: "Is Adult Product",
      field: "isAdultProduct",
      width: 130,
      cellRenderer: (params: any) => {
        return params.value ? "Check" : "X";
      },
    },
  ]);

  useEffect(() => {
    token();
    setSearchValue("");
    setIsGobalFilter("");
  }, []);

  useEffect(() => {
    getAll();
  }, [isCurrentPage, selectedOption]);

  const getAll = async () => {
    setIsLoading(true);
    try {
      // let IsFavoriteFile = userParams.code === "Favorite";
      let IsFavoriteFile;
      if (userParams.code === "Favorite") {
        IsFavoriteFile = true;
      } else {
        IsFavoriteFile = false;
      }
      const rulesResponse = await shippingSettings();
      const responseInArray = rulesResponse.data?.shippingRules.map(
        (value: any, index: any) => {
          return {
            id: index,
            from: value.from,
            to: value.to,
            price: value.cost,
          };
        }
      );
      setIsCheckboxChecked(rulesResponse.data?.isShippingRulesEnabled);
      setShippingCostValue(rulesResponse.data?.shippingCostPerPound);
      setRows(
        responseInArray?.length
          ? responseInArray
          : [{ id: Date.now(), from: "", to: "", price: "" }]
      );
      const scanSettingResponse = await scanSettings();
      if (scanSettingResponse) {
        setIsAmazonCheck(scanSettingResponse.data?.amazonCheck);
        setIsKeepaChartDataRange(scanSettingResponse.data?.keepaChartDateRange);
        setIsPrepFee(scanSettingResponse.data?.prepFeePerItem);
      }
      const request = { page: isCurrentPage, perPage: 50 };
      const object = {
        productId: userParams.id,
        request: request,
        IsFavoriteFile: IsFavoriteFile,
        selectedOption: selectedOption,
        isShippingRulesEnabled: rulesResponse?.data?.isShippingRulesEnabled,
        amazonCheck: scanSettingResponse.data?.amazonCheck,
        keepaChartDateRange: scanSettingResponse.data?.keepaChartDateRange,
        shippingCostPerPound: rulesResponse.data?.shippingCostPerPound,
      };
      const res = await productDetails(object);
      // const res = await productDetails(
      //   userParams.id,
      //   request,
      //   IsFavoriteFile,
      //   selectedOption
      // );

      setIsResponse(res.data);
      const rowArray = res.data.data || [];
      const rowData = rowArray.map((item: any, idx: number) => ({
        ...item,
        agGridId: idx,
      }));
      setIsRow(rowData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Graph logic
  const handleChartIconHover = (params: any) => {
    setIsGraph(null);
    setIsGraphLoading(true);
    setKeepaImageCheck(true);
    const requestObj = {
      country: params.data.countryCode,
      asin: params.data.asin,
      range: 30,
    };
    getGraph(requestObj);
  };

  const getGraph = async (requestObj: any) => {
    try {
      setIsGraph(null);
      setIsGraphLoading(true);
      const resp = await getGraphDetails(requestObj);
      const imageBuffer = resp.data;
      const base64Image = btoa(
        new Uint8Array(imageBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      const imageUrl = `data:image/png;base64,${base64Image}`;
      setIsGraph(imageUrl);
      setHoveredImage(imageUrl);
    } catch (err) {
      console.error("Error fetching graph", err);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const handleMouseEnter = (imageUrl: any) => {
    setHoveredImage(imageUrl);
  };
  const handleMouseLeave = () => {
    setHoveredImage(null);
    setKeepaImageCheck(false);
  };

  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const onSelectionChanged = useCallback((event: any) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node: any) => node.data.productId);
    setSelectedRowsData(selectedIds);
  }, []);

  // Export logic
  const handleOkHandler = () => {
    if (isExport) {
      addExport();
    }
  };
  const toBase64 = (str: string) => btoa(unescape(encodeURIComponent(str)));

  const addExport = async () => {
    try {
      setIsExportLoading(true);
      const response = await axios.post(
        `https://api.sellerscout.com/ProductUpload/${userParams.id}/ExportProducts`,
        { page: 0, perPage: 10 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
            ExportFileName: toBase64(isExport),
            CompressZip: String(isCompressChecked),
            IsFavoriteFile: "false",
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = isCompressChecked
        ? `${isExport}-data.zip`
        : `${isExport}-data.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      toast.success("Export successfully");
    } catch (error) {
      toast.error("Failed to export data");
      console.error("Export failed", error);
    } finally {
      setIsCompressChecked(false);
      setIsExport(null);
      setIsExportLoading(false);
    }
  };

  // Favorites logic
  const showModal = () => {
    // e.g. check selected rows if needed
    setIsModalOpen(true);
    getFavList();
  };
  const handleOk = () => {
    setIsModalOpen(false);
    addFavList();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getFavList = async () => {
    try {
      const response = await getFavListSource(105);
      setIsFavList(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const favListShow = isFavList.map((item: any) => (
    <MenuItem key={item.favoriteId} value={item.favoriteId}>
      {item.favoriteName}
    </MenuItem>
  ));
  const changeHandler = (e: SelectChangeEvent) => {
    setIsList({ ...islist, [e.target.name]: e.target.value });
    setIsOkButtonDisabled(false);
  };
  const checkIfFavListExists = async () => {
    try {
      const response = await FavListIsExists(isInput);
      if (response.data === true) {
        message.error("Name already exists. Please create a new name.");
      } else {
        setIsOkButtonDisabled(true);
        await postFavListSourceCall();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const submitList = async () => {
    checkIfFavListExists();
  };
  const postFavListSourceCall = async () => {
    if (isInput) {
      try {
        const resp = await postFavListSource(isInput);
        if (resp.status === 200) {
          getFavList();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  const addFavList = async () => {
    const request = {
      productUploadId: userParams.id,
      productIds: selectedRowsData,
      favoriteId: islist.favorite,
      sourceId: 105,
    };
    try {
      const response = await addFavListProduct(request);
      if (response.data && response.data.success) {
        const successMessage = response.data.description || "successful";
        message.success(successMessage);
      } else {
        const errorMessage = response.data?.description || "failed";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error(err);
      message.error("API call failed");
    }
  };
  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  // Scan Setting
  const settingModalSettingClose = () => {
    hetRulesSetting();
    getRulesSetting();
    const modal = document.querySelector(".modal") as HTMLElement;
    if (modal) {
      modal.style.display = "none";
    }
    setShippingSettingsError(false);
    setShippingRoleError(false);
    document.body.classList.remove("modal-open");
    setIsShowModal(false);
    setIsShowNewModal(false);
  };

  const formatDateTime = (dateTimeString: any) => {
    return moment(dateTimeString).format("MM/DD/YY hh:mm A");
  };

  const onCellValueChanged = async (params: any) => {
    const rowIdentifier = params.data?.productSearch;
    console.log("rowIdentifier", rowIdentifier);
    if (!rowIdentifier) {
      return;
    }

    const changedField = params.colDef.field;
    const newValue = params.newValue;
    const updateInfo: any = { [changedField]: newValue };

    try {
      await productUpdate(rowIdentifier, updateInfo);
      message.success(`Updated ${changedField} to ${newValue} successfully!`);
    } catch (error) {
      console.error("Update failed:", error);
      message.error("Failed to update on server.");
    }
  };

  // settings
  const hetRulesSetting = async () => {
    try {
      const scanSettingResponse = await scanSettings();
      if (scanSettingResponse) {
        setIsAmazonCheck(scanSettingResponse.data?.amazonCheck);
        setIsKeepaChartDataRange(scanSettingResponse.data?.keepaChartDateRange);
        setIsPrepFee(scanSettingResponse.data?.prepFeePerItem);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  const getRulesSetting = async () => {
    try {
      const response = await shippingSettings();
      const responseInArray = response.data?.shippingRules.map(
        (value: any, index: any) => {
          return {
            id: index,
            from: value.from,
            to: value.to,
            price: value.cost,
          };
        }
      );
      setIsCheckboxChecked(response.data?.isShippingRulesEnabled);
      setRows(
        responseInArray?.length
          ? responseInArray
          : [{ id: Date.now(), from: "", to: "", price: "" }]
      );
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleUpdateScan = async () => {
    try {
      setScanSettingLoading(true);
      const object = {
        defaultCondition: 0,
        defaultMarketPlace: 0,
        emailNotifications: true,
        keepaSubscription: true,
        amazonCheck: Number(isAmazonCheck),
        keepaChartDateRange: Number(isKeepaChartDataRange),
        prepFee: Number(isPrepFee),
      };
      await scanSettingsUpdate(object);
      setIsShowModal(false);
      toast.success("Scan Settings updated successfully.");
      getAll();
    } catch (e) {
      console.log("e", e);
    } finally {
      setScanSettingLoading(false);
    }
  };
  const handleAddRow = () => {
    setRows([{ id: Date.now(), from: "", to: "", price: "" }, ...rows]);
  };

  const handleDeleteRow = (id: number) => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleScanSettingChange = (
    id: number,
    field: keyof RowData,
    value: string
  ) => {
    setRows((prevRows) => {
      const otherFields = prevRows.filter((row) => row.id !== id);
      const isDuplicate = otherFields.some((row) => row[field] === value);

      if (isDuplicate) return prevRows;

      return prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
    });
  };
  const shippingSettingUpdates = async () => {
    try {
      const responseInArray = rows
        ?.map((value) => {
          return {
            from: Number(value.from),
            to: Number(value.to),
            cost: Number(value.price),
          };
        })
        .filter((item) => item.from && item.to && item.cost);
      if (
        !isCheckboxChecked &&
        (!shippingCostValue || Number(shippingCostValue) <= 0)
      ) {
        setShippingSettingsError(true);
        return;
      }

      if (isCheckboxChecked && responseInArray.length === 0) {
        setShippingRoleError(true);
        return;
      }
      setShippingRoleError(false);
      const object = {
        shippingRules: responseInArray,
        isShippingRulesEnabled: isCheckboxChecked,
        shippingCostPerPound: Number(shippingCostValue),
      };
      setShippingSettingsLoading(true);
      await shippingSettingsUpdate(object);
      toast.success("Shipping Settings updated successfully.");
      setIsShowNewModal(false);
      getAll();
    } catch (e) {
      console.log("e", e);
    } finally {
      setShippingSettingsLoading(false);
    }
  };
  return (
    <section className="ranks-offers">
      {isLoading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <Container fluid>
          {isExportLoading && (
            <div className="ranks-loader-particular">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <Row>
            <Col>
              <div className="product-item-table">
                <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
                  <div className="d-flex flex-column">
                    <h1 className="title-heading">{userParams.file}</h1>
                    <span className="title-heading-span">
                      {formatDateTime(userParams.time)}
                    </span>
                  </div>
                  {/* Preset Dropdown */}
                  <Box display="flex" alignItems="center" gap={2} mb={2}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      sx={{ minWidth: 200 }}
                    >
                      <InputLabel id="preset-select-label">
                        Select Preset
                      </InputLabel>
                      <Select
                        labelId="preset-select-label"
                        value={selectedPreset}
                        onChange={async (event: SelectChangeEvent<string>) => {
                          const presetId = event.target.value;
                          setSelectedPreset(presetId);
                          if (presetId) {
                            try {
                              const preset = await getPresetById(presetId);
                              if (gridApi) {
                                const columnState = JSON.parse(
                                  preset.configuration
                                );
                                // Use the new applyColumnState method:
                                gridApi.applyColumnState({
                                  state: columnState,
                                  applyOrder: true,
                                });
                              } else {
                                console.warn("Grid API is not ready", gridApi);
                              }
                            } catch (error) {
                              console.error("Error applying preset:", error);
                            }
                          }
                        }}
                        label="Preset"
                      >
                        <MenuItem value="">
                          <em>Select Preset</em>
                        </MenuItem>
                        {presets.map((preset) => (
                          <MenuItem
                            key={preset.presetId}
                            value={preset.presetId}
                          >
                            {preset.preset}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      type="primary"
                      onClick={() => setIsPresetModalOpen(true)}
                    >
                      Save Current View
                    </Button>
                  </Box>
                  <div className="d-flex gap-2 align-items-center">
                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        width: "140px",
                      }}
                      className="fba-radio"
                    >
                      <label style={{ display: "flex", marginRight: "10px" }}>
                        <input
                          type="radio"
                          value="FBA"
                          checked={selectedOption === "FBA"}
                          onChange={handleChange}
                          style={{ transform: "scale(1.5)" }}
                        />
                        <span style={{ marginLeft: "8px" }}>FBA</span>
                      </label>
                      <label style={{ display: "flex" }}>
                        <input
                          type="radio"
                          value="FBM"
                          checked={selectedOption === "FBM"}
                          onChange={handleChange}
                          style={{ transform: "scale(1.5)" }}
                        />
                        <span style={{ marginLeft: "8px" }}>FBM</span>
                      </label>
                    </div>

                    <Popconfirm
                      title="Enter Name"
                      description={
                        <div>
                          <Input
                            onChange={(e) => setIsExport(e.target.value)}
                          />
                          <div className="pt-2 d-flex align-items-center gap-2">
                            <input
                              type="checkbox"
                              className="checkbox-desc"
                              style={{ width: "19px", height: "19px" }}
                              onChange={(e) =>
                                setIsCompressChecked(e.target.checked)
                              }
                              checked={isCompressChecked}
                            />
                            Compress
                          </div>
                        </div>
                      }
                      onConfirm={handleOkHandler}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" className="boxering-btn">
                        <div className="d-flex align-items-center">
                          <HiDownload className="me-2" />
                          Export
                        </div>
                      </Button>
                    </Popconfirm>
                    {/* <Button
                      type="primary"
                      onClick={showModal}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        <IoMdAdd className="me-2" />
                        Add to favorite
                      </div>
                    </Button> */}
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsShowNewModal(true);
                      }}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        Rules Setting
                      </div>
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => setIsShowModal(true)}
                      className="boxering-btn"
                    >
                      <div className="d-flex align-items-center">
                        Scan Settings
                      </div>
                    </Button>
                  </div>
                </div>

                <Box sx={{ height: 800, width: "100%" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <AgGridReact
                      theme={myTheme}
                      modules={[
                        ClientSideRowModelModule,
                        ValidationModule,
                        RowSelectionModule,
                        NumberFilterModule,
                        TextFilterModule,
                        DateFilterModule,
                        ColumnsToolPanelModule,
                        FiltersToolPanelModule,
                        PivotModule,
                        SideBarModule,
                      ]}
                      rowData={isRow}
                      columnDefs={columnDefs}
                      pagination={true}
                      loadThemeGoogleFonts={true}
                      rowSelection="multiple"
                      onSelectionChanged={onSelectionChanged}
                      sideBar={{
                        toolPanels: [
                          {
                            id: "columns",
                            labelKey: "columns",
                            labelDefault: "Columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel",
                          },
                          {
                            id: "filters",
                            labelKey: "filters",
                            labelDefault: "Filters",
                            iconKey: "filter",
                            toolPanel: "agFiltersToolPanel",
                          },
                        ],
                        defaultToolPanel: "",
                        hiddenByDefault: false,
                      }}
                      tooltipShowDelay={500}
                      onCellValueChanged={onCellValueChanged}
                      enableBrowserTooltips={false}
                      onGridReady={onGridReady}
                      singleClickEdit={true}
                    />
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
          {/* --- Save Preset Modal --- */}
          <Modal
            title="Save Preset"
            visible={isPresetModalOpen}
            onCancel={() => setIsPresetModalOpen(false)}
            footer={[
              <Button key="cancel" onClick={() => setIsPresetModalOpen(false)}>
                Cancel
              </Button>,
              <Button
                key="save"
                type="primary"
                onClick={async () => {
                  if (gridApi && presetName.trim() !== "") {
                    const columnState = gridApi.getColumnState();
                    try {
                      await createPreset(
                        presetName,
                        JSON.stringify(columnState)
                      );
                      await fetchPresetsFromAPI();
                      setPresetName("");
                      setIsPresetModalOpen(false);
                    } catch (error) {
                      console.error("Failed to save preset:", error);
                    }
                  }
                }}
              >
                Save
              </Button>,
            ]}
            width={300}
            wrapClassName="preset-modal-custom"
          >
            <TextField
              placeholder="Enter preset name"
              fullWidth
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
            />
          </Modal>

          <OverviewModal
            show={show}
            setShow={setShow}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          <ScanSettingModal
            setIsPrepFee={setIsPrepFee}
            isPrepFee={isPrepFee}
            setIsAmazonCheck={setIsAmazonCheck}
            isAmazonCheck={isAmazonCheck}
            isShowModal={isShowModal}
            isKeepaChartDataRange={isKeepaChartDataRange}
            setIsKeepaChartDataRange={setIsKeepaChartDataRange}
            settingModalSettingClose={settingModalSettingClose}
            handleUpdateScan={handleUpdateScan}
            scanSettingLoading={scanSettingLoading}
          />
          <ScanNewModal
            shppingCostValue={shippingCostValue}
            handleDeleteRow={handleDeleteRow}
            setIsCheckboxChecked={setIsCheckboxChecked}
            isCheckboxChecked={isCheckboxChecked}
            setShippingCostValue={setShippingCostValue}
            handleAddRow={handleAddRow}
            handleScanSettingChange={handleScanSettingChange}
            rows={rows}
            isShowNewModal={isShowNewModal}
            settingModalSettingClose={settingModalSettingClose}
            shippingSettingUpdates={shippingSettingUpdates}
            shippingSettingsLoading={shippingSettingsLoading}
            shippingSettingsError={shippingSettingsError}
            setShippingSettingsError={setShippingSettingsError}
            shippingRoleError={shippingRoleError}
            setShippingRoleError={setShippingRoleError}
          />
          <ImageTooltip
            imageUrl={hoveredImage}
            show={!!hoveredImage}
            onClose={handleMouseLeave}
            keepaImageCheck={keepaImageCheck}
          />
         <KeepaChartTooltip
            imageUrl={isGraph}
            show={!!isGraph}
            onClose={handleMouseLeave}
            keepaImageCheck={keepaImageCheck}
          />
          {/* Favorites Modal */}
          <Modal
            title="Add Products to Favorite"
            className="basic-modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: isOkButtonDisabled }}
          >
            <div className="d-flex justify-content-center">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
                <InputLabel
                  id="demo-simple-select-standard-label"
                  style={{ textAlign: "left" }}
                >
                  Select Favorites List
                </InputLabel>
                <Select
                  name="favorite"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={islist.favorite || ""}
                  onChange={changeHandler}
                  label="Select Favorites List"
                >
                  {favListShow}
                </Select>
                <br />
                <br />
                <div className="d-flex align-items-baseline">
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Add New Favorite"
                    variant="standard"
                    name="add"
                    onChange={(e) => setIsInput(e.target.value)}
                    required
                    InputLabelProps={{
                      style: { textAlign: "left" },
                    }}
                  />
                  <div>
                    <Button type="primary" onClick={submitList}>
                      Add
                    </Button>
                  </div>
                </div>
              </FormControl>
            </div>
          </Modal>
        </Container>
      )}
    </section>
  );
};

export default ParticularItem;
