import React from "react";

const ImageTooltip = ({ imageUrl, show, onClose }: any) => {
  if (!show) return null;
  return (
    <button
      className="image-tooltip"
      onMouseEnter={() => {}}
      onMouseLeave={onClose}
      onClick={onClose}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClose();
        }
      }}
      aria-label="Product Preview"
      style={{ border: "none", padding: "0px" }}
    >
      <div className="image-tooltip-content">
        <img src={imageUrl} alt="Product Preview" />
      </div>
    </button>
  );
};

export default ImageTooltip;
