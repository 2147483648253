import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import "./index.css";
import Loader from "../Loader";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import {
  ProductUploadProcess,
  addProductUpload,
  productUpload,
  token,
  uploadFile,
} from "../../Service/services";
import { Alert, Col, Row } from "react-bootstrap";
import { Checkbox, ListItemText, Tooltip } from "@mui/material";
import { getUploadScanClass } from "../../helpers/commanFunction";
interface YourComponentProps {
  getProductUpload: any;
  isAmazonCredentials: any;
  getSummery?: any;
}
const UploadFile: React.FC<YourComponentProps> = ({
  getProductUpload,
  isAmazonCredentials,
  getSummery,
}) => {
  const [selectedRadio, setSelectedRadio] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isList, setIsList] = useState<any>({
    custom: [],
    product: [],
    cost: [],
  });
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isError, setIsError] = useState<any>(null);
  const [productData, setProductData] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isResponse, setIsResponse] = useState<any>({});
  const [isCustomError, setIsCustomError] = useState<any>(null);
  const [isColumns, setIsColumns] = useState<any>([]);
  const [isRow, setIsRow] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const userData = JSON.parse(localStorage.getItem("userProfile") ?? "{}");
  const [checked, setChecked] = useState(false);
  const handleCheckChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    window.scroll(0, 0);
    token();
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsList({ custom: [] });
    setIsModalOpen(false);
    setSelectedFile(null);
    setIsCustomError(null);
    setIsLimitExceeded(false);
  };

  const handleTemplate = () => {
    setIsModalOpen(false);
  };

  const validateFields = () => {
    const errors: any = {};

    if (!productData.productIdColumnSelectedItem) {
      errors.productIdColumnSelectedItem = "Product ID Column is required.";
    }
    if (!productData.costColumnSelectedItem) {
      errors.costColumnSelectedItem = "Cost Column is required.";
    }
    if (!productData?.productIdTypeSelectedItem) {
      errors.productIdTypeSelectedItem = "Search By is required.";
    }
    if (!productData.productIdTypeSelectedItem) {
      errors.productIdTypeSelectedItem = "Search type is required.";
    }
    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const startHandler = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await uploadFile(formData);
        if (response.status === 200) {
          setIsLoading(false);
          setIsResponse(response.data);
          setValue(response.data.fileNameOnly);
          setProductData(response.data);
          setIsList((prevIsList: any) => ({
            ...prevIsList,
            product: [response?.data?.productIdColumnSelectedItem],
            cost: [response?.data?.costColumnSelectedItem],
          }));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.error("Please select a file first");
    }
  };
  const addproducts = async () => {
    if (!validateFields()) {
      message.error("Please fill all required fields.");
      return;
    }
    setIsLoading(true);
    const request = {
      fileSearchType: isResponse.fileSearchType,
      fileName: value + isResponse.fileSearchType,
      filePath: isResponse.filePath,
      productIdType: selectedRadio,
      productIdColumn: isList?.product[0],
      productCostColumn: isList?.cost[0],
      uploadProcessingStatus: 0,
      haveExtraColumn: true,
      haveHeader: true,
      processedPercentage: 0,
      numberOfProducts: isResponse.numberOfRecords,
      additionalColumns: "",
      emailNotification: true,
      SourceId: 105,
      enablePowerUpData: checked,
    };
    try {
      const response = await addProductUpload(request);
      if (response.status === 200) {
        if (!response.data.success) {
          setIsCustomError(response.data.description);
          setIsLoading(false);
        } else {
          const request = {
            page: 1,
            perPage: 10,
          };
          setSelectedFile(null);
          setIsModalOpen(false);
          setIsLoading(false);
          message.success("Processed Succesfully");
          productUpload(request);
          ProductUploadProcess();
          getProductUpload();
          getSummery();
        }
      }
    } catch (error: any) {
      console.log(error.response?.data?.ErrorMessage);
      if (error.response?.data?.ErrorMessage == "Plan limit exceeded") {
        setIsLimitExceeded(true);
        setIsLoading(false);
      }
    }
  };
  const customColum = isResponse.productIdColumn?.map((item: any) => (
    <MenuItem key={item} value={item}>
      <Checkbox checked={isList.custom.indexOf(item) > -1} />
      <ListItemText primary={item} />
    </MenuItem>
  ));

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];

      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please upload a CSV or Excel file.");
        event.target.value = "";
        return;
      }
      setSelectedFile(file);
      setIsError(null);
      setIsLoading(true);
    }
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const isValidFileType = (file: any) => {
    return (
      file.type === "text/csv" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (!file || !isValidFileType(file)) {
      setIsError("Invalid file type. Please drop a CSV or Excel file.");
      return;
    }

    setSelectedFile(file);
    setIsError(null);
    setIsLoading(true);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };
  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    setIsList((prevIsList: any) => ({
      ...prevIsList,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));

    if (typeof value === "string" && value.trim() !== "") {
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: null,
      }));
    }

    setOpen(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!isResponse.data || isResponse.data.length === 0) {
      setIsColumns([]);
      setIsRow([]);
    } else {
      const columns = Object.keys(isResponse.data[0]).map((column) => {
        const baseColumn = {
          field: column,
          headerName: column,
          minWidth: getUploadScanClass(column),
          flex: 1,
        };
        if (column === "fileName") {
          return {
            ...baseColumn,
            headerName: "File Name",
            renderCell: (params: any) => (
              <div className="table-text">{params.row.fileName}</div>
            ),
          };
        } else return baseColumn;
      });
      const rows = isResponse.data.map((item: any, index: any) => ({
        id: index,
        ...item,
      }));
      setIsColumns(columns);
      setIsRow(rows);
    }
  }, [isResponse.data]);

  useEffect(() => {
    if (productData?.productIdTypeSelectedItem === "UPC/EAN") {
      setSelectedRadio("UPC");
    } else {
      setSelectedRadio(productData?.productIdTypeSelectedItem || "");
    }
  }, [productData]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);
  useEffect(() => {
    if (selectedFile) {
      startHandler();
    }
  }, [selectedFile]);
  return (
    <div className="upload-file-modal">
      <button
        className={isAmazonCredentials ? "scan-box-allowed" : "scan-box"}
        onClick={showModal}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") showModal();
        }}
        disabled={isAmazonCredentials}
        style={{ border: "none" }}
      >
        Upload Files <span className="browse-text"> browse</span>
      </button>
      <Modal
        width={1000}
        title="Start Scan"
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        footer={
          !isLoading
            ? [
                selectedFile ? (
                  <div className="w-100">
                    <div className="mb-3 mt-3 text-start">
                      {isLimitExceeded ? (
                        <Alert
                          key={"danger"}
                          variant={"danger"}
                          className="alert-message"
                        >
                          You have exceeded your current plan limit. Please
                          upgrade your plan to continue using this feature.
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="d-flex btns-main ">
                      <Button
                        key="save"
                        onClick={handleCancel}
                        className="scan-btn-cancel"
                      >
                        Cancel
                      </Button>
                      <Button
                        key="template"
                        onClick={addproducts}
                        className="save-btn-start"
                        disabled={isLimitExceeded}
                      >
                        Start
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex btns-main">
                    <Button
                      key="save"
                      onClick={handleCancel}
                      className="save-btn-cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      key="template"
                      onClick={handleTemplate}
                      className="save-btn-template  "
                    >
                      Template
                    </Button>
                  </div>
                ),
              ]
            : null
        }
      >
        <form
          className="form-container"
          encType="multipart/form-data"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div>
            {(() => {
              if (isLoading) {
                return (
                  <div className="Loader">
                    <Loader />
                  </div>
                );
              }
              if (selectedFile) {
                return (
                  <div className="uploadfile-table">
                    <span className="text-danger">{isCustomError}</span>
                    <p className="starting">Starting Position</p>
                    <div className="uploadfile">
                      <p className="m-0 p-0 upload-banner">
                        {isResponse.fileName}
                      </p>
                    </div>
                    <div
                      style={{ height: "300px", width: "100%" }}
                      className="upload-table"
                    >
                      <DataGrid
                        className="scan-table"
                        rows={isRow}
                        columns={isColumns}
                        hideFooter={true}
                        getRowId={(row) => row.id}
                        checkboxSelection={false}
                        onRowSelectionModelChange={(data: any) => {}}
                      />
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <FormControl>
                          <div className="d-flex align-items-center flex-wrap ">
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              className="mx-2"
                            >
                              Search By*
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={selectedRadio}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value="UPC"
                                control={<Radio />}
                                label="UPC"
                              />
                              <FormControlLabel
                                value="EAN"
                                control={<Radio />}
                                label="EAN"
                              />
                              <FormControlLabel
                                value="ASIN"
                                control={<Radio />}
                                label="ASIN"
                              />
                              <FormControlLabel
                                value="ISBN"
                                control={<Radio />}
                                label="ISBN"
                              />
                            </RadioGroup>
                          </div>
                          {validationErrors.productIdTypeSelectedItem && (
                            <span className="error">
                              {validationErrors.productIdTypeSelectedItem}
                            </span>
                          )}
                        </FormControl>

                        <Box
                          component="form"
                          sx={{
                            "& > :not(style)": { m: 1, width: "40ch" },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            className="mr-0"
                            id="standard-basic"
                            label="File Name"
                            variant="standard"
                            value={value}
                            onChange={handleChange}
                          />
                        </Box>
                      </div>

                      <Row className="mb-4  input-row">
                        <Col
                          lg={4}
                          md={6}
                          sm={6}
                          xs={6}
                          className="pl-0 product-input"
                        >
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="label-input"
                            >
                              Product ID Column*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={isList?.product[0]}
                              name="product"
                              onChange={changeHandler}
                              label="Product ID Columns*"
                            >
                              {productData?.productIdColumn.map(
                                (item: string, index: number) => (
                                  <MenuItem
                                    key={`${item}-${index}`}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {validationErrors.productIdColumnSelectedItem && (
                              <span className="error">
                                {validationErrors.productIdColumnSelectedItem}
                              </span>
                            )}
                          </FormControl>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="label-input"
                            >
                              Marketplace*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={isList?.market}
                              name="market"
                              onChange={changeHandler}
                              label="Product ID Columns*"
                              defaultValue={10}
                            >
                              <MenuItem value={10}>US</MenuItem>
                              <MenuItem value={20}>EU</MenuItem>
                              <MenuItem value={30}>UA</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="label-input"
                            >
                              Cost Column*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label "
                              id="demo-simple-select-standard"
                              value={isList?.cost[0]}
                              name="cost"
                              onChange={changeHandler}
                              label="Product ID Columns*"
                            >
                              {productData?.costColumn.map(
                                (item: string, index: number) => (
                                  <MenuItem
                                    key={`${item}${index}`}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            {validationErrors.costColumnSelectedItem && (
                              <span className="error">
                                {validationErrors.costColumnSelectedItem}
                              </span>
                            )}
                          </FormControl>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={6}
                          xs={6}
                          className="pl-0 product-input"
                        >
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="label-input"
                            >
                              Currency*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label relative"
                              id="demo-simple-select-standard"
                              value={isList?.currency}
                              name="currency"
                              onChange={changeHandler}
                              label="Product ID Columns*"
                              defaultValue={10}
                            >
                              <MenuItem value={10}>USD</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-multiple-select-label"
                              className="label-input"
                            >
                              Custom Columns
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-select-label"
                              id="demo-multiple-select"
                              multiple
                              value={isList?.custom}
                              name="custom"
                              onChange={(e) => changeHandler(e)}
                              renderValue={(selected) =>
                                Array.isArray(selected)
                                  ? selected.join(", ")
                                  : ""
                              }
                              open={open}
                              onOpen={handleOpen}
                              onClose={handleClose}
                              label="Product ID Columns*"
                            >
                              {customColum}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6}>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%", maxWidth: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="label-input"
                            >
                              Condition*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={isList.condition}
                              name="condition"
                              onChange={changeHandler}
                              label="Product ID Columns*"
                              defaultValue={10}
                            >
                              <MenuItem value={10}>NEW</MenuItem>
                              <MenuItem value={20}>USED</MenuItem>
                            </Select>
                          </FormControl>
                        </Col>
                        <Col
                          lg={3}
                          md={6}
                          sm={6}
                          xs={6}
                          className="enable-power-up-data d-flex align-items-center"
                        >
                          {(() => {
                            if (userData?.isPowerUser) {
                              return (
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checked}
                                        onChange={handleCheckChange}
                                      />
                                    }
                                    label="Enable Power-Up Data"
                                  />
                                </FormGroup>
                              );
                            }
                            return (
                              <Tooltip
                                title={
                                  <p
                                    className="mb-0 p-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Upgrade to a paid plan to unlock Power Up
                                    feature
                                  </p>
                                }
                                // open={true}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox disabled />}
                                    label="Enable Power-Up Data"
                                  />
                                </FormGroup>
                              </Tooltip>
                            );
                          })()}
                        </Col>
                      </Row>
                    </div>
                  </div>
                );
              }
              return (
                <label className="label-area upload" htmlFor="file-upload">
                  <span className="text-danger">{isError}</span>
                  <div>
                    <input
                      type="file"
                      id="file-upload"
                      className="default-file-input"
                      accept=".csv, .xlsx, .xls"
                      onChange={handleFileChange}
                    />
                    <span className="browse-files">Click to select a file</span>
                  </div>
                  <div>
                    <CloudUploadIcon className="upload-icon" />
                  </div>
                  <p className="clicktoselect">Drop & Upload a file</p>
                </label>
              );
            })()}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default UploadFile;
