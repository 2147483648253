import React, { useState } from "react";
import { Form, Container, Row, Col, FormLabel, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoginSlider from "../Components/LoginSlider";
import "./index.css";
import { forgetPassword } from "../Service/services";
import toast from "react-hot-toast";

type Inputs = {
  email: string;
};

const ResetPassword: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    try {
      setLoading(true);
      const object = {
        email: data.email,
        passwordRedirectUrl: `${process.env.REACT_APP_SERVER_URL}/reset-password?token=`,
      };
      const response = await forgetPassword(object);
      if (response.data) {
        toast.success(
          "A password reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your password."
        );
      }
    } catch (error: any) {
      toast.error(error.response.data.ErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={5} md={12} xs={12} className="p-0">
          <LoginSlider />
        </Col>
        <Col
          lg={7}
          md={12}
          xs={12}
          className="p-0 d-flex justify-content-center align-items-center"
        >
          <div className="login-form-section">
            <div className="login-form-content">
              <h1 className="mb-2">Forgot Password?</h1>
              <p>
                Enter your email address and we’ll send you a link to reset your
                password.
              </p>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-0 password-cont">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel className="label-text">Email Address*</FormLabel>
                  <Form.Control
                    type="email"
                    placeholder="Enter your Email"
                    className="form-input-text"
                    {...register("email", {
                      required: "*Please enter your email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error-message text-danger sapn-text-error mt-1">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <button
                type="submit"
                className="login-btn mt-3"
                disabled={loading}
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    aria-hidden="true"
                    className="loader"
                  />
                )}
              </button>
            </Form>
            <p className="create-account text-center">
              Back to{" "}<button
                type="button"
                className="sign-in-link"
                onClick={() => navigate("/")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    navigate("/");
                  }
                }}
                aria-label="Sign in"
                style={{ cursor: "pointer", padding: "0px", border: "none" }}
              >
                Login
              </button>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
