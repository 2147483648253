import { useEffect, useState } from "react";
import ChartDemo from "../../charts";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { AddCircles, Exports } from "../../../utils";
import { GoArrowSwitch } from "react-icons/go";
import { CiZoomIn } from "react-icons/ci";

interface ChartsProps {
  chartsData: any;
  orignalData: any;
  chartsLoader: any;
  setChartsLoader: any;
  searchResult?: any;
}

const Charts = ({
  chartsData,
  orignalData,
  chartsLoader,
  searchResult,
  setChartsLoader
}: ChartsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [chartsLocalData, setChartsLocalData] = useState<any>([]);
  const [filterNumber, setFilterNumber] = useState<number>(3);
  const [dragActive, setDragActive] = useState<number>(1);
  const legendStyle =  {
    itemStyle: {
      fontSize: "10px",
    },
    enabled: true,
    verticalAlign: "top",
    align: "center",
    borderWidth: 2,
    borderColor: "rgba(192, 192, 192, 0.5)",
    borderStyle: "solid",
    borderRadius: 0,
    stroke: "rgba(192, 192, 192, 0.5)",
    strokeWidth: 1,
  }
  const filterDataByTimeRange = (data: (number[] | null)[], filter: number) => {
    const currentTime = new Date().getTime();
    let timeRange = 0;
    let latestDate: Date | null = null;
    switch (filter) {
      case 0:
        timeRange = 24 * 60 * 60 * 1000; 
        break;
      case 1:
        timeRange = 7 * 24 * 60 * 60 * 1000;
        break;
      case 2:
        timeRange = 30 * 24 * 60 * 60 * 1000; 
        break;
      case 3:
        timeRange = 90 * 24 * 60 * 60 * 1000; 
        break;
      case 4:
        timeRange = 365 * 24 * 60 * 60 * 1000; 
        break;
      case 5:
        return orignalData;
      default:
        return orignalData;
    }
    const latestDateMillis = currentTime - timeRange;
    latestDate = new Date(latestDateMillis);
    const currentUnixTime = Math.floor(Date.now() / 1000);
    const earliestDate = new Date(currentUnixTime * 1000);

    let filteredData = data.map((chart, index) => {
      if (!chart?.length) return chart;

      let filteredData2: any = chart.filter((item: any) => {
        const dataTime = new Date(item.date).getTime();
        return currentTime - dataTime <= timeRange;
      });

      let lastValueBeforeCutoff: any;
      if (
        (filteredData2.length === 0 && index == 9) ||
        index === 6 ||
        index === 7
      ) {
        const cutoffTime = currentTime - timeRange;

        lastValueBeforeCutoff = chart
          .filter((item: any) => {
            const dataTime = new Date(item.date).getTime();
            return dataTime < cutoffTime;
          })
          .sort(
            (a: any, b: any) =>
              new Date(b.date).getTime() - new Date(a.date).getTime()
          )
          .shift();

        if (lastValueBeforeCutoff) {
          filteredData2.push({
            date: latestDate,
            price: lastValueBeforeCutoff?.price || 0,
          });
        }
        filteredData2.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      }

      let firstObject: any;
      if (index === 6) {
        firstObject = {
          date: latestDate,
          price: lastValueBeforeCutoff?.price || 0,
        };
      } else {
        firstObject = {
          date: latestDate,
          price:
            filteredData2[filteredData2.length - 1]?.price ??
            ([7, 8].includes(index) ? 1 : 0),
        };
      }

      let lastObject: any = {
        date: earliestDate,
        price:
          filteredData2[filteredData2.length - 1]?.price ??
          ([7, 8].includes(index) ? 1 : 0),
      };
      filteredData2.push(firstObject);
      filteredData2.push(lastObject);
      filteredData2.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      return filteredData2;
    });
    return filteredData;
  };

  const handleNavigateToCharts = () => {
    navigate("/view-charts", {
      state: {
        chartsData: chartsData,
        orignalData: orignalData,
        chartsLoader: chartsLoader,
        
      },
    });
  };

  const handleClick = (filter: any) => {
    setFilterNumber(filter);
  };
  const handleDragCLick = (id: any) => {
    setDragActive(id);
  };
  useEffect(() => {
    if (chartsData?.length) {
      const filteredData = filterDataByTimeRange(chartsData, filterNumber);
      setChartsLocalData(filteredData);
    } else {
      setChartsLocalData([]);
    }
  }, [chartsData]);
  const renderChartContent = () => {
    if (chartsLoader) {
      return <div className="loading-buyBox" style={{ overflow: "hidden", height: "200px" }}></div>;
    }
  
    if (chartsLocalData?.length) {
      return (
        <ChartDemo
          chartsData={chartsData}
          legendStyle={legendStyle}
          dragActive={dragActive}
          filterNumber={filterNumber}
          orignalData={orignalData}
        />
      );
    }
  
    return null;
  };
  return (
    <div>
      <div>{renderChartContent()}</div>
      <div className="chart-buttons d-flex align-items-center flex-wrap">
        <Tooltip
          title={<div className="content-tooltip">Show 1 Day</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 0 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(0)}
          >
            DY
          </button>
        </Tooltip>

        <Tooltip
          title={<div className="content-tooltip">Show 1 Week</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 1 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(1)}
          >
            WK
          </button>
        </Tooltip>

        <Tooltip
          title={<div className="content-tooltip">Show 1 Month</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 2 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(2)}
          >
            MO
          </button>
        </Tooltip>

        <Tooltip
          title={<div className="content-tooltip">Show 3 Months</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 3 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(3)}
          >
            3 Mo
          </button>
        </Tooltip>

        <Tooltip
          title={<div className="content-tooltip">Show 1 Year</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 4 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(4)}
          >
            Yr
          </button>
        </Tooltip>

        <Tooltip
          title={<div className="content-tooltip">Show All Time</div>}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <button
            className={`discount-btn-refresh ${
              filterNumber === 5 ? "btn-refresh" : ""
            }`}
            onClick={() => handleClick(5)}
          >
            All
          </button>
        </Tooltip>
      </div>
      <div className="other-btns d-flex align-items-center flex-wrap gap-4">
        <div className="d-flex gap-2">
          <Tooltip
            title={
              <div className="content-tooltip">
                Drag the chart along with the data line
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <button
              className={`discount-btn-refresh ${
                dragActive == 1
                  ? "btn-refresh gap-1 d-flex align-items-center"
                  : ""
              }`}
              onClick={() => handleDragCLick(1)}
            >
              <GoArrowSwitch size={18} className="goArrow-icon" />
            </button>
          </Tooltip>
          <Tooltip
            title={
              <div className="content-tooltip">
                Select an area on the chart to zoom in
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <button
              className={`discount-btn-refresh ${
                dragActive == 2
                  ? "btn-refresh gap-1 d-flex align-items-center"
                  : ""
              }`}
              onClick={() => handleDragCLick(2)}
            >
              <CiZoomIn size={20} className="goArrow-icon" />
            </button>
          </Tooltip>
        </div>
        <div className="d-flex gap-2">
          <Tooltip
            title={
              <div className="content-tooltip">
                Open this chart in a new window
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <button className="icons-btns" onClick={handleNavigateToCharts}>
              <img src={Exports} alt="copy" className="w-full" />
            </button>
          </Tooltip>
          <Tooltip
            title={<div className="content-tooltip">View chart on keepa</div>}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <a
              href={`https://keepa.com/#!product/1-${searchResult}`}
              className="icons-btns d-flex justify-content-center align-items-center"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <img src={AddCircles} alt="copy" className="w-full" />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Charts;
