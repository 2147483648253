import React, { useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { formatElapsedTime, useStyles } from "../../../helpers";
import { Tooltip } from "@mui/material";
import { getRankAndPriceValue } from "../../../helpers/commanFunction";

interface RanksAndPricesProps {
  ranksAndPrices: any;
  rankFilter: any;
  activeKey: any;
  setRankFilter: React.Dispatch<React.SetStateAction<any>>;
  loading: any;
  setActiveKey: React.Dispatch<React.SetStateAction<any>>;
  getRanksAndPrices: any;
  handleToggle: any;
  calculateNetPrice: any;
  allRanksData: any;
  setRanksAndPrices:any;
  setCalculateNetPrice: any;
}
interface FilterOption {
  tooltip: string;
  label: string;
  rankFilterValue: number;
}
const RanksAndPrices = ({
  ranksAndPrices,
  rankFilter,
  setRankFilter,
  activeKey,
  loading,
  setActiveKey,
  getRanksAndPrices,
  handleToggle,
  calculateNetPrice,
  allRanksData,
  setRanksAndPrices,
  setCalculateNetPrice
}: RanksAndPricesProps) => {
  const classes = useStyles();
  const [startTime, setStartTime] = useState(new Date());
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const lastRefreshedString = localStorage.getItem("ranksRefreshTime");
      const lastRefreshed = lastRefreshedString
        ? new Date(lastRefreshedString)
        : null;

      const elapsedMs = lastRefreshed
        ? new Date().getTime() - lastRefreshed.getTime()
        : 0;

      setElapsedTime(elapsedMs);
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime]);

  const handleRefreshClick = () => {
    localStorage.setItem("ranksRefreshTime", new Date().toString());
    setStartTime(new Date());
    getRanksAndPrices();
  };
  const filterOptions: FilterOption[] = [
    { tooltip: "Current", label: "Current", rankFilterValue: 0 },
    { tooltip: "Average 30 Days", label: "30", rankFilterValue: 1 },
    { tooltip: "Average 90 Days", label: "90", rankFilterValue: 2 },
    { tooltip: "Average 180 Days", label: "180", rankFilterValue: 3 },
    { tooltip: "Lifetime", label: "All", rankFilterValue: 4 },
  ];
  const handleClick = (linkName: number) => {
    const rankKeys: { [key: number]: keyof typeof allRanksData } = {
      1: "Days30",
      2: "Days90",
      3: "Days180",
      4: "All",
    };
  
    const selectedRank = allRanksData?.[rankKeys[linkName] || "Current"];
  
    if (selectedRank) {
      setRanksAndPrices(selectedRank);
      setCalculateNetPrice(
        selectedRank.netBuyBoxPriceChanges?.increased - 
        selectedRank.netBuyBoxPriceChanges?.decreased
      );
    }
  
    setRankFilter(linkName);
  };

  const priceData = [
    {
      key: "BSR",
      value: ranksAndPrices?.bsr,
      description: "BSR",
    },
    {
      key: "buyBox",
      value: ranksAndPrices?.buyBox?.average,
      description: "Buy Box",
    },
    {
      key: "amazon",
      value: ranksAndPrices?.amazon?.average,
      description: "Amazon",
    },
    {
      key: "lowestFBA",
      value: ranksAndPrices?.lowestFBA?.average,
      description: "Lowest FBA",
    },
    {
      key: "lowestFBM",
      value: ranksAndPrices?.lowestFBM?.average,
      description: "Lowest FBM (Sellers)",
    },
  ];
  const renderPriceRow = ({ key, value, description }: any) => (
    <tr key={key}>
      <td style={{ width: "50%" }} className="ranks-description">
        {description}
      </td>
      <td style={{ textAlign: "right" }}>
        <span
          id="saslookup-profit"
          className="enough-description cursor-pointer d-flex justify-content-end align-items-center gap-2"
        >
          {getRankAndPriceValue(key, value)}
          {key === "amazon" &&
            value > 0 &&
            ranksAndPrices.isAmazonBuyBoxWinner && (
              <span className="buy-Box-span">Buy Box</span>
            )}
            {key === "lowestFBA" &&
            value > 0 &&
            ranksAndPrices.isLowestNewFBAIsBuyBoxWinner && (
              <span className="buy-Box-span">Buy Box</span>
            )}
          {key === "lowestFBM" &&
            value > 0 &&
            ranksAndPrices.isLowestNewFBMIsBuyBoxWinner && (
              <span className="buy-Box-span">Buy Box</span>
            )}
        </span>
      </td>
    </tr>
  );
  return (
    <div id="ranks-prices">
      <Accordion
        activeKey={activeKey?.ranks === true ? "0" : "1"}
        className="ranks-offers"
      >
        {loading ? (
          <div className="ranks-loader">
            <Spinner animation="border">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <></>
        )}
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={() => handleToggle("ranks")}>
            Ranks & Prices
          </Accordion.Header>
          <Accordion.Body className="ranks-body">
            <div className="">
              <ul className="padding-content d-flex align-items-center justify-content-start mb-0 p-0 flex-wrap">
                {filterOptions.map((option) => (
                  <Tooltip
                    key={option.rankFilterValue}
                    title={
                      <div className="content-tooltip">{option.tooltip}</div>
                    }
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <li>
                      <button
                        className={`discount-btn-refresh ${
                          rankFilter === option.rankFilterValue
                            ? "btn-refresh"
                            : "discount-btn-refresh"
                        }`}
                        onClick={() => handleClick(option.rankFilterValue)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        rel="noopener noreferrer"
                      >
                        {option.label}
                      </button>
                    </li>
                  </Tooltip>
                ))}
              </ul>
              <table className="table bootstrap-table table-custom table-striped table-bordered mb-0">
                <tbody>
                  {priceData.map(renderPriceRow)}
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      Keepa BSR Drops
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        id="saslookup-profit"
                        className="enough-description"
                      >
                        {ranksAndPrices?.keepaBSRDrops ?? "n/a"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      <Tooltip
                        title={
                          <div className="content-tooltip">
                            Net count of Buy Box price increases or decreases in
                            selected time period. <br />
                            An excessive number of net price decreases can
                            indicate a price war.
                          </div>
                        }
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          rel="noopener noreferrer"
                        >
                          <span className="ranks-description dotted-border cursor-pointer">
                            Net BB Price Changes
                          </span>
                        </div>
                      </Tooltip>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        id="saslookup-profit"
                        className="seller-description d-flex align-items-center justify-content-end"
                      >
                        {calculateNetPrice === 0 ? (
                          <Tooltip
                            title={
                              <div className="content-tooltip">
                                Buy Box price changes in selected time period
                                <br /> Increases:
                                {
                                  ranksAndPrices?.netBuyBoxPriceChanges
                                    ?.increased
                                }
                                <br />
                                Decreases:
                                {
                                  ranksAndPrices?.netBuyBoxPriceChanges
                                    ?.decreased
                                }
                              </div>
                            }
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <div
                              className="enough-description dotted-border"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              rel="noopener noreferrer"
                            >
                              <span className="enough-description cursor-pointer">
                                0
                              </span>
                            </div>
                          </Tooltip>
                        ) : (
                          <>
                            {calculateNetPrice > 0 ? (
                              <FaLongArrowAltUp className="down-up" />
                            ) : (
                              <FaLongArrowAltDown className="down-error" />
                            )}
                            <Tooltip
                              title={
                                <div className="content-tooltip">
                                  Buy Box price changes in selected time period
                                  <br /> Increases:
                                  {
                                    ranksAndPrices?.netBuyBoxPriceChanges
                                      ?.increased
                                  }
                                  <br />
                                  Decreases:
                                  {
                                    ranksAndPrices?.netBuyBoxPriceChanges
                                      ?.decreased
                                  }
                                </div>
                              }
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <div
                                className="enough-description dotted-border"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                rel="noopener noreferrer"
                              >
                                <span className="enough-description cursor-pointer">
                                  {Math.abs(calculateNetPrice)}&nbsp;
                                  {rankFilter === 0 && "last 30 days"}
                                </span>
                              </div>
                            </Tooltip>
                          </>
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      Estimated Sales
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        id="saslookup-profit"
                        className="enough-description"
                      >
                        {ranksAndPrices?.estimatedSales ?? "0"} / mo
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      <Tooltip
                        title={
                          <div className="content-tooltip">
                            Use with CAUTION this is an estimate and in Beta.
                            <br />
                            This is the estimated time to sell your first unit.
                            <br />
                            Based on your sale price, current stock levels and
                            sales being shared between similar priced offers.
                            <br />
                            Prices and stock levels DO change.
                          </div>
                        }
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          rel="noopener noreferrer"
                        >
                          <span className="ranks-description dotted-border cursor-pointer">
                            Est. Time to Sale
                          </span>
                        </div>
                      </Tooltip>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        id="saslookup-profit"
                        className="enough-description d-flex align-items-center justify-content-end"
                      >
                        {ranksAndPrices?.estTimetoSale !== "Not enough data" &&
                          ranksAndPrices?.estTimetoSale && (
                            <FaLongArrowAltDown className="down-error" />
                          )}

                        <div
                          className="enough-description"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          rel="noopener noreferrer"
                        >
                          <span className="enough-description">
                            {ranksAndPrices?.estTimetoSale ?? "Not enough data"}
                          </span>
                        </div>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }} className="ranks-description">
                      Last Checked&nbsp;
                      {formatElapsedTime(elapsedTime)}
                    </td>
                    <td
                      className="verticle-middle"
                      style={{ textAlign: "right" }}
                    >
                      <Tooltip
                        title={<div className="content-tooltip">Refresh</div>}
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <button
                          className="btn-refresh btn-blue d-flex align-items-center gap-1 justify-content-end ms-auto"
                          onClick={handleRefreshClick}
                        >
                          Refresh
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default RanksAndPrices;
