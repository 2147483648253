import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { GoAlertFill } from "react-icons/go";

import { useStyles } from "../../../../helpers";
import {
  profitCalculatorFulfilmentTypeClass,
  profitCalculatorFulfilmentTypeValue,
  profitCalculatorReferralFeeTooltips,
} from "../../../../helpers/commanFunction";
import EditableField from "./editableField";
import { useProfitCalculator } from "../../hooks/useProfitCalculator";

interface TotalFeesProps {
  productDetails: any;
  setProductDetails: (details: any) => void;
}

const TotalFeesSection = ({
  productDetails,
  setProductDetails,
}: TotalFeesProps) => {
  const classes = useStyles();

  const {
    handleProfitTotalFees,
    calculateMiscFeePercentageOfCost,
    calculateInboundPlacement,
  } = useProfitCalculator({
    productDetails,
    setProductDetails,
  });

  const [inboundPlacementValue, setInboundPlacementValue] = useState({
    name: "",
    fee: 0,
  });

  return (
    <ul
      className="detail-items d-flex flex-column gap-2"
      style={{ width: "100%" }}
    >
      <li className="d-flex justify-content-between align-items-center">
        <span className="ranks-description">
          <Tooltip
            title={
              <div className="referral-fee-tooltip">
                {/* {profitCalculatorReferralFeeTooltips(
                  productDetails?.profitCalculator?.totalFees?.referralFee
                )} */}
                %
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <span className="ranks-description dotted-border cursor-pointer">
              Referral Fee
            </span>
          </Tooltip>
        </span>
        <span className="enough-description">
          $&nbsp;
          {productDetails?.profitCalculator?.totalFees?.referralFee}
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span className="ranks-description">
          Fulfilment&nbsp; (
          {profitCalculatorFulfilmentTypeValue(
            productDetails?.profitCalculator?.fulfilmentType
          )}
          ) &nbsp;
          {productDetails?.profitCalculator?.fulfilmentType == "FBA" &&
          productDetails.profitCalculator.totalFees.fulfilment_FBA == 0 ? (
            <Tooltip
              title={
                <div className="referral-fee-tooltip">FBA Fee Unknown</div>
              }
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <span className="dotted-border cursor-pointer">
                <GoAlertFill size={16} color="#A94442" />
              </span>
            </Tooltip>
          ) : (
            <></>
          )}
        </span>
        <span className="enough-description">
          $&nbsp;
          {productDetails?.profitCalculator?.totalFees?.fulfilmentFee}
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span className="ranks-description">Closing Fee</span>
        <span className="enough-description">
          $&nbsp;
          {productDetails?.profitCalculator?.totalFees?.closingFee.toFixed(2)}
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span
          className={`${profitCalculatorFulfilmentTypeClass(
            productDetails?.profitCalculator?.fulfilmentType
          )}`}
        >
          Storage Fee
        </span>
        <span className="enough-description">
          $&nbsp;
          {productDetails?.profitCalculator?.totalFees?.storageFee}
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span
          className={`${profitCalculatorFulfilmentTypeClass(
            productDetails?.profitCalculator?.fulfilmentType
          )}`}
        >
          Prep Fee
        </span>
        <span className="enough-description">
          $&nbsp;
          <EditableField
            value={productDetails?.profitCalculator?.totalFees?.prepFee}
            onSave={(newValue) => handleProfitTotalFees("prepFee", newValue)}
          />
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span
          className={`${profitCalculatorFulfilmentTypeClass(
            productDetails?.profitCalculator?.fulfilmentType
          )}`}
        >
          Inbound Shipping
        </span>
        <span className="enough-description">
          $&nbsp;
          <EditableField
            value={productDetails?.profitCalculator?.totalFees?.inboundShipping}
            onSave={(newValue) =>
              handleProfitTotalFees("inboundShipping", newValue)
            }
          />
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span>
          <Tooltip
            title={
              <div className="referral-fee-tooltip">
                Estimated maximum Inbound Placement Service fee for Amazon
                Optimized Splits. Storage Fee
                <br />
                <br />
                Click to cycle through the different Inbound Placement services.
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <span
              className={`${profitCalculatorFulfilmentTypeClass(
                productDetails?.profitCalculator?.fulfilmentType
              )}`}
              role="button"
              onClick={() => {
                const result = calculateInboundPlacement();
                setInboundPlacementValue(result);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  calculateInboundPlacement();
                }
              }}
            >
              Inbound Placement (
              {inboundPlacementValue.name
                ? inboundPlacementValue.name.slice(0, 1).toUpperCase()
                : "O"}
              )
            </span>
          </Tooltip>
        </span>
        <span className="enough-description">
          $&nbsp;
          {inboundPlacementValue.fee.toFixed(2)}
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span className="ranks-description">Misc Fee</span>
        <span className="enough-description">
          $&nbsp;
          <EditableField
            value={productDetails?.profitCalculator?.totalFees?.miscFee}
            onSave={(newValue) => handleProfitTotalFees("miscFee", newValue)}
          />
        </span>
      </li>
      <li className="d-flex justify-content-between align-items-center">
        <span className="ranks-description">
          Misc Fee (
          <EditableField
            value={
              productDetails?.profitCalculator?.totalFees
                ?.miscFee_PercentageOfCost
            }
            onSave={(newValue) =>
              handleProfitTotalFees("miscFee_PercentageOfCost", newValue)
            }
          />
          % of cost)
        </span>
        <span className="enough-description">
          $&nbsp;
          {calculateMiscFeePercentageOfCost()}
        </span>
      </li>
    </ul>
  );
};

export default TotalFeesSection;
