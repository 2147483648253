import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Spinner, Alert, Image } from "react-bootstrap";
import "./index.css";
import { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  deleteProductUpload,
  productUpload,
  productUploadSummary,
  token,
} from "../../Service/services";
import Loader from "../Loader";
import CustomPagination from "../Pagination";
import ScanCards from "../ScanCards";
import { SearchContext } from "../../context/searchContext";
import toast from "react-hot-toast";
import axios from "axios";
import ConfirmationModal from "./confirmationModal";
import { deleteIcon, downloadIcon } from "../../utils";
import {
  scanColumnIdType,
  scanTableColumn,
} from "../../helpers/commanFunction";
const Token = localStorage.getItem("accessToken");

interface RowData {
  fileName?: string;
  date?: string;
  numberOfRecords?: number;
  processedPercentage?: number;
  countryCode?: string;
  idtype?: string;
  condition?: string;
  productUploadId?: string;
  enablePowerUpData?: string;
  [key: string]: any;
}

const Scans: React.FunctionComponent = () => {
  const [isData, setIsData] = useState<any>([]);
  const [isResponse, setIsResponse] = useState<any>([]);
  const [isRow, setIsRow] = useState<RowData[]>([]);
  const [isColumns, setIsColumns] = useState<GridColDef[]>([]);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isTableLoading, setIsTableLoading] = useState<any>(false);
  const [isPerPage, setIsPerPage] = useState<any>(10);
  const [isCurrentPage, setIsCurrentPage] = useState<any>(1);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [loadingRowsDelete, setLoadingRowsDelete] = useState<any>(false);
  const [productId, setProductId] = useState<any>("");
  const [modalShow, setModalShow] = React.useState(false);
  const [isAmazonCredentials, setIsAmazonCredentials] = useState(false);
  const [totalValue, setTotalValue] = useState<any>({});
  const navigate = useNavigate();
  const { setSearchValue, setIsGobalFilter } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();
  useEffect(() => {
    token();
  }, []);
  useEffect(() => {
    getProductUpload();
    setIsTableLoading(true);
  }, [isCurrentPage, isPerPage]);
  const getProductUpload = async () => {
    let request = {
      page: isCurrentPage,
      perPage: isPerPage,
    };

    try {
      const response = await productUpload(request);
      if (response.status === 200) {
        setIsResponse(response?.data);
        setIsData(response?.data?.data);
      }
    } catch (error: any) {
      if (error?.response?.data?.ErrorMessage === "Invalid token") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userProfile");
        navigate("/");
      } else if (
        error?.response?.data?.ErrorMessage == "Amazon Credentials Not Added"
      ) {
        setIsAmazonCredentials(true);
      }
    } finally {
      setIsLoading(false);
      setIsTableLoading(false);
    }
  };
  const toBase64 = (str: string) => {
    return btoa(encodeURIComponent(str));
  };
  const handleExportFile = async (filename: any) => {
    try {
      setIsExportLoading(true);
      const response = await axios.post(
        `https://testapi.sellerscout.com/ProductUpload/${filename.productUploadId}/ExportProducts`,
        {
          page: 0,
          perPage: 20000000,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
            ExportFileName: toBase64(filename.fileName),
            CompressZip: "false",
            IsFavoriteFile: "false",
          },
          responseType: "blob",
        }
      );
      // Handle the file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename.fileName}-data.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      toast.success("Export successfully");
      console.log("Export successful");
    } catch (e) {
      console.log("e", e);
    } finally {
      setIsExportLoading(false);
    }
  };
  useEffect(() => {
    setSearchValue("");
    setIsGobalFilter("");
  }, []);
  useEffect(() => {
    if (!isData || isData.length === 0) {
      setIsColumns([]);
      setIsRow([]);
      return;
    }

    const getRenderCell = (column: string, params: any) => {
      switch (column) {
        case "fileName":
          return (
            <button
              className="table-text tables-heads"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{
                fontWeight: "400",
                cursor: "pointer",
                border: "none",
                padding: "0px",
              }}
            >
              {params.row.fileName}
            </button>
          );
        case "date":
          return (
            <button
              className="centered-cell"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{ cursor: "pointer", border: "none", padding: "0px" }}
            >
              {new Date(params.value).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}
            </button>
          );
        case "numberOfRecords":
          return (
            <button
              className="centered-cell table-text"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{ cursor: "pointer", border: "none", padding: "0px" }}
            >
              {params.row.numberOfRecords}
            </button>
          );
        case "processedPercentage":
          return (
            <button
              className="centered-cell badge-cell"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{ cursor: "pointer", border: "none", padding: "0px" }}
            >
              <div className="completed-badge">
                {params.value === 0 ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  params.value === 100 && "Completed"
                )}
              </div>
            </button>
          );
        case "countryCode":
          return (
            <button
              className="centered-cell"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{ cursor: "pointer", border: "none", padding: "0px" }}
            >
              {params.row[column]}
            </button>
          );
        case "enablePowerUpData":
          return (
            <button
              className="centered-cell"
              onClick={() => handleTitleClick(params)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleTitleClick(params);
                }
              }}
              style={{ cursor: "pointer", border: "none", padding: "0px" }}
            >
              {params.row.enablePowerUpData.toString()}
            </button>
          );
        default:
          return params.value;
      }
    };

    // Creates column configuration
    const getColumnConfig = (column: string): GridColDef => ({
      field: column,
      headerName: scanTableColumn(column),
      renderCell: (params) => getRenderCell(column, params),
      width: scanColumnIdType(column),
      flex: 1,
    });
    const columns = Object.keys(isData[0]).map((column) =>
      getColumnConfig(column)
    );

    // Additional static columns
    columns.push(
      {
        field: "condition",
        headerName: "Condition",
        renderCell: (params: any) => (
          <button
            className="centered-cell"
            onClick={() => handleTitleClick(params)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleTitleClick(params);
              }
            }}
            style={{ cursor: "pointer", border: "none", padding: "0px" }}
          >
            {params.row.condition || "New"}
          </button>
        ),
        width: 100,
        flex: 1,
      },
      {
        field: "idtype",
        headerName: "ID Type",
        renderCell: (params: any) => (
          <button
            className="centered-cell"
            onClick={() => handleTitleClick(params)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleTitleClick(params);
              }
            }}
            style={{ cursor: "pointer", border: "none", padding: "0px" }}
          >
            {params.row.idtype || "UPC"}
          </button>
        ),
        width: 100,
        flex: 1,
      },
      {
        field: "buttons",
        headerName: "",
        renderCell: (params: any) => (
          <div className="d-flex gap-2 centered-cell padding-icon">
            <Tooltip title={<p className="mb-0 p-1">Delete</p>}>
              <span>
                <button
                  className="btn-refresh-scans"
                  onClick={() => handleDeleteProductConfirmation(params.row)}
                  disabled={!!loadingRowsDelete}
                >
                  <Image src={deleteIcon} alt="scan" height={40} width={40} />
                </button>
              </span>
            </Tooltip>
            <Tooltip title={<p className="mb-0 p-1">Download Excel</p>}>
              <button
                className="btn-download-scans"
                onClick={() => handleExportFile(params.row)}
              >
                <Image src={downloadIcon} alt="scan" height={40} width={40} />
              </button>
            </Tooltip>
          </div>
        ),
        width: 100,
        flex: 1,
      }
    );
    const rows = isData.map((item: any, index: any) => ({
      id: index,
      idtype: item.idtype || "UPC",
      ...item,
    }));
    const updatedColumns = columns.filter(
      (column) => column.field !== "productUploadId"
    );

    setIsColumns(updatedColumns);
    setIsRow(rows);
  }, [isData]);

  const handleDeleteProductConfirmation = (productId: any) => {
    setModalShow(true);
    setProductId(productId.productUploadId);
  };
  const modalClose = () => {
    setModalShow(false);
    setProductId("");
    setLoadingRowsDelete(false);
  };
  const handleDeleteProduct = async () => {
    try {
      setLoadingRowsDelete(true);
      const object = {
        productUploadId: productId,
      };
      const response = await deleteProductUpload(object);
      if (response) {
        toast.success("Product deleted successfully!");
        getProductUpload();
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoadingRowsDelete(false);
      setModalShow(false);
    }
  };
  const handlePageChange = (newPage: number) => {
    setIsCurrentPage(newPage);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setIsPerPage(event.target.value);
  };
  const handleTitleClick = (params: any) => {
    const { productUploadId, countryCode, date, fileName, enablePowerUpData } =
      params.row;
    const path = `/product-details/${productUploadId}/${countryCode}/${date}/${fileName}/${enablePowerUpData}`;
    navigate(path);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getProductUpload();
      const inProgressRows = isRow?.filter(
        (row: any) => row.processedPercentage === 0
      );
      if (inProgressRows.length === 0) {
        clearInterval(interval);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [isRow]);

  useEffect(() => {
    const getTotalRecordsCount = () => {
      try {
        if (isResponse) {
          isResponse.data.reduce(
            (acc: number, item: any) => acc + item.numberOfRecords,
            0
          );
        }
      } catch (error) {
        console.error("Error calculating total count:", error);
      }
    };

    getTotalRecordsCount();
  }, [isResponse]);
  const handleRedirect = () => {
    navigate("/panels");
    const state: any = false;
    localStorage.setItem("AmazonCredentialsModal", state);
  };

  const getSummery = async () => {
    try {
      const summeryResponse = await productUploadSummary();
      setTotalValue(summeryResponse.data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getSummery();
  }, []);
  return (
    <section className="px-2 ranks-offers pb-5">
      {isExportLoading ? (
        <div className="ranks-loader-particular">
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <Container fluid className="px-2">
          {isAmazonCredentials ? (
            <Alert key={"danger"} variant={"danger"} className="alert-message">
              Amazon credentials are missing. Please add them in the settings to
              continue.
              <button
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                onClick={handleRedirect}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleRedirect();
                  }
                }}
              >
                Go to setting
              </button>
            </Alert>
          ) : (
            <></>
          )}
          <div className="mt-2 px-2">
            <ScanCards
              isAmazonCredentials={isAmazonCredentials}
              totalCount={isResponse.total}
              totalValue={totalValue}
              getProductUpload={getProductUpload}
              getSummery={getSummery}
            />
          </div>
          <div className="w-100 scans-table-page">
            <Col md={12} sm={12} className="p-0">
              {isTableLoading ? (
                <div
                  className="loading-loader table-loading new-scans-table"
                  style={{
                    minHeight: "400px",
                    maxHeight: "400px",
                    height: "400px",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="new-scans-table">
                    <DataGrid
                      rows={isRow}
                      columns={isColumns}
                      hideFooter={true}
                      getRowId={(row: any) => row.id}
                      checkboxSelection={false}
                    />
                  </div>
                  <div className="pagination-dialog">
                    <CustomPagination
                      currentPage={isCurrentPage}
                      totalPages={isResponse.lastPage}
                      rowsPerPage={isPerPage}
                      totalResults={isResponse.total}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleChange}
                    />
                  </div>
                </>
              )}
            </Col>
          </div>
        </Container>
      )}
      <ConfirmationModal
        modalShow={modalShow}
        modalClose={modalClose}
        onConfirm={handleDeleteProduct}
        loadingRowsDelete={loadingRowsDelete}
      />
    </section>
  );
};

export default Scans;
