import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../../AlertBox";
import Dropdown from "./variationModalDropdown";

interface ModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  handleSort: (sortLevels: SortLevel[]) => void;
  filteredColumns: { id: string; Header: string }[];
}

interface SortLevel {
  column: string;
  order: string;
}

const VariationsModalComponent = ({
  showModal,
  handleCloseModal,
  handleSort,
  filteredColumns,
}: ModalProps) => {
  // Function to get default sort level
  const getDefaultSortLevel = (): SortLevel => ({
    column: filteredColumns?.[0]?.id || "",
    order: "asc",
  });

  const [sortLevels, setSortLevels] = useState<SortLevel[]>([getDefaultSortLevel()]);
  const [errorMessage, setErrorMessage] = useState("");

  // Add new sorting level
  const addLevel = () => {
    if (sortLevels.length < filteredColumns.length) {
      setSortLevels([...sortLevels, getDefaultSortLevel()]);
    }
  };

  // Remove last sorting level
  const deleteLevel = () => {
    if (sortLevels.length > 1) {
      setSortLevels(sortLevels.slice(0, -1));
    }
  };

  // Handle sorting input change
  const handleSortChange = (index: number, field: keyof SortLevel, value: string) => {
    setSortLevels((prevLevels) =>
      prevLevels.map((level, i) => (i === index ? { ...level, [field]: value } : level))
    );
  };

  // Check for duplicate columns
  const hasDuplicateColumns = (sortLevels: SortLevel[]) => {
    const seenColumns = new Set(sortLevels.map((level) => level.column));
    return seenColumns.size !== sortLevels.length;
  };

  // Handle sorting logic
  const handleSorting = () => {
    if (hasDuplicateColumns(sortLevels)) {
      setErrorMessage("Please remove or change any duplicate column.");
    } else {
      setErrorMessage("");
      handleCloseModal();
      handleSort(sortLevels);
    }
  };

  // Reusable dropdown component
  

  return (
    <Modal show={showModal} onHide={handleCloseModal} className="modal-filter">
      <Modal.Header closeButton>
        <Modal.Title>Multiple Sort</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <AlertBox text={errorMessage} />}
        <div className="d-flex gap-3 align-items-center mt-2">
          <button className="transparent-btn" onClick={addLevel}>
            Add Level
          </button>
          <button
            className={`transparent-btn ${sortLevels.length > 1 ? "" : "disabled-button"}`}
            onClick={deleteLevel}
            disabled={sortLevels.length <= 1}
          >
            Delete Level
          </button>
        </div>
        <div className="fixed-table-container mt-2">
          <table id="multi-sort" className="table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <div className="th-inner">Column</div>
                </th>
                <th>
                  <div className="th-inner">Order</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortLevels.map((level:any, index:any) => (
                <tr key={`${level}-${index}`}>
                  <td>{index === 0 ? "Sort by" : "Then by"}</td>
                  <td>
                    <Dropdown
                      value={level.column}
                      options={filteredColumns.map((column) => ({
                        label: column.Header,
                        value: column.id,
                      }))}
                      onChange={(value) => handleSortChange(index, "column", value)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      value={level.order}
                      options={[
                        { label: "Ascending", value: "asc" },
                        { label: "Descending", value: "desc" },
                      ]}
                      onChange={(value) => handleSortChange(index, "order", value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <button className="transparent-btn" onClick={handleCloseModal}>
            Cancel
          </button>
          <button className="btn-refresh seller-btn" onClick={handleSorting}>
            Sort
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VariationsModalComponent;
