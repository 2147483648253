import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
const AddMarketplaceModal = ({
  addMarketplaceModal,
  handleAddMarketplaceModalCLose,
  appConfigResponse,
}: any) => {
  return (
    <div>
      <Modal
        show={addMarketplaceModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-filter-marketplace"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Choose an Amazon Marketplace Region
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={25}
            onClick={handleAddMarketplaceModalCLose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex p-5">
            {appConfigResponse?.map(
              ({ endpoint, title, image }: any, index: number) => (
                <Col
                  md={6}
                  className="d-flex flex-column justify-content-center align-items-center"
                  key={`${title}-${index}`}
                >
                  <a href={endpoint} className="country-name-a">
                    <p className="country-name-p pb-1">{title}</p>
                    <img
                      src={image}
                      alt={title || "Marketplace"}
                      className="marketplace-image"
                    />
                  </a>
                </Col>
              )
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddMarketplaceModalCLose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddMarketplaceModal;
