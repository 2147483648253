import React, { useState } from "react";
import TextArea from "./textArea";
import { IoStar } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Star } from "../../../utils";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
interface NotesProps {
  data: any;
  productDetails: any;
  getAllNotes: any;
  notes?: any;
  setAllNotes?: any;
  searchResult: any;
}
const Notes = ({
  data,
  productDetails,
  getAllNotes,
  notes,
  setAllNotes,
  searchResult,
}: NotesProps) => {
  const [textAreas, setTextAreas] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState<{ [key: string]: boolean }>({});
  const addTextArea = () => {
    const newNoteId = Date.now();
    setTextAreas((prev: any) => [
      ...prev,
      {
        id: newNoteId,
        note: "",
      },
    ]);
    setIsEditable((prev: any) => ({ ...prev, [newNoteId]: true }));
  };
  return (
    <div
      id="notes"
      className="offers-contentsss d-flex flex-column  flex-wrap ranks-offers"
    >
      {isLoading ? (
        <div className="ranks-loader">
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <></>
      )}
      <div className="d-flex gap-2">
        {data?.map((item: any, rowIndex: any) =>
          item.type === "icon" ? (
            <Tooltip
              key={`${item}-${rowIndex}`}
              title={<div className="content-tooltip">Favorite</div>}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={item.link} target={item.target} className="btn-refresh">
                <img src={Star} alt="star" />
                <IoStar className="d-none" />
              </Link>
            </Tooltip>
          ) : (
            <Tooltip
              key={`${item}-${rowIndex}`}
              title={<div className="content-tooltip">Add Note</div>}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <button
                type="button"
                onClick={() => addTextArea()}
                className="btn-refresh"
              >
                {item.title}
              </button>
            </Tooltip>
          )
        )}
      </div>
      <div className="text-area-wrapper">
        <TextArea
          data={[]}
          productDetails={productDetails}
          notes={notes}
          setAllNotes={setAllNotes}
          textAreas={textAreas}
          searchResult={searchResult}
          setIsLoading={setIsLoading}
          setTextAreas={setTextAreas}
          setIsEditable={setIsEditable}
          isEditAble={isEditable}
        />
      </div>
    </div>
  );
};

export default Notes;
