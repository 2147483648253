import { NavLink } from "react-router-dom";
import { logout } from "../Service/services";
import { SiAwsorganizations } from "react-icons/si";
import { RiHistoryFill, RiBillLine } from "react-icons/ri";
import { TbScan } from "react-icons/tb";
import { MdOutlineInstallMobile } from "react-icons/md";
import { ImYoutube } from "react-icons/im";
import { PiRanking } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
interface SiderbarProps {
  isOpen?: boolean;
}

const Sidebar = ({ isOpen }: SiderbarProps) => {
  const navigate = useNavigate();
  const logoutHandler = async () => {
    try {
      const response = await logout();
      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userProfile");
        localStorage.removeItem("ProfilePicture");
        localStorage.removeItem("lastASINSearch");
        // window.location.href = "/login";
        navigate("/login");
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <nav className={`main-menu  ${isOpen ? "open" : "collapsed"}`}>
      <ul className="router-name scroll-sidebar">
        <span className="product-analyzer-span">Product Analyzer</span>
        <li className="pt-1">
          <NavLink to="/">
            <SiAwsorganizations className="fa" />
            <span className="nav-text">Product Analyzer</span>
          </NavLink>
        </li>
        <li className="pt-1">
          <NavLink to="/history">
            <RiHistoryFill className="fa" />
            <span className="nav-text">History</span>
          </NavLink>
        </li>
        <li className="hr-border"></li>
        <span className="product-analyzer-span mt-2">Bulk Scan</span>
        <li className="pt-1">
          <NavLink to="/scans">
            <TbScan className="fa" />
            <span className="nav-text">Scans</span>
          </NavLink>
        </li>
        <li className="hr-border"></li>
        <span className="product-analyzer-span mt-2">Help</span>
        <li className="pt-1">
          <NavLink to="/help">
            <MdOutlineInstallMobile className="fa" />
            <span className="nav-text">Install Apps</span>
          </NavLink>
        </li>
        <li className="pt-1">
          <a
            href="https://www.youtube.com/channel/UCqAArMMS5T6fbVjIvKAaiVw"
            target="_blank"
          >
            <ImYoutube className="fa" />
            <span className="nav-text">Youtube</span>
          </a>
        </li>
        <li className="pt-1">
          <NavLink to="/details">
            <PiRanking className="fa" />
            <span className="nav-text">Sales Rank Table</span>
          </NavLink>
        </li>
        <li className="hr-border"></li>
        <span className="product-analyzer-span mt-2">My Account</span>
        <li className="pt-1">
          <NavLink to="/panels">
            <IoSettingsOutline className="fa" />
            <span className="nav-text">Settings</span>
          </NavLink>
        </li>
        <li className="pt-1">
          <NavLink to="/subscriptions">
            <RiBillLine className="fa" />
            <span className="nav-text">Subscriptions</span>
          </NavLink>
        </li>
      </ul>

      <ul className="logout">
        <li>
          <button
            type="button"
            onClick={logoutHandler}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") logoutHandler();
            }}
            className="logout-button"
            aria-label="Sign out"
            style={{border: "none", padding: "0px"}}
          >
            <HiOutlineLogout className="fa" />
            <span>Sign Out</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
