import React, { useState, useRef, useEffect } from "react";
import { FiEdit, FiCheck } from "react-icons/fi";

interface EditableFieldProps {
  value: number;
  onSave: (newValue: number) => void;
}

const EditableField: React.FC<EditableFieldProps> = ({ value, onSave }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<number>(value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSave = () => {
    const newValue = parseFloat(inputValue.toFixed(2));
    onSave(newValue);
    setIsEditing(false);
    setIsHovering(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEditing &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        handleSave();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing, inputValue]);

  return (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
        if (!isEditing) setInputValue(value);
      }}
    >
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            className="w-25"
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(parseFloat(e.target.value) || 0)}
            autoFocus
          />
        </>
      ) : (
        <span>
          {value.toFixed(2)}
          {isHovering && (
            <FiEdit
              className="ms-1 mb-1 cursor-pointer"
              onClick={() => setIsEditing(true)}
            />
          )}
        </span>
      )}
    </span>
  );
};

export default EditableField;
