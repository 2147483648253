import React, { useContext, useEffect } from "react";
import Main from "./Layout/main";
import { Route, Routes } from "react-router-dom";
import { SearchContext } from "./context/searchContext";
import Authorize from "./Components/authorize";
import Plan from "./Components/Plan";
import Login from "./UserLoginFlow/login";
import Register from "./UserLoginFlow/register";
import ResetPassword from "./UserLoginFlow/resetPassword";
import ConfirmPassword from "./UserLoginFlow/confirmPassword";
import EmailVerify from "./Components/emailVerify";
import PrivateComponent from "./PrivateComponent";
import Widgets from "./Components/Widgets";
import Panels from "./Components/Panels";
import DetailsTable from "./Components/DetailsTable";
import Subscription from "./Components/Subscriptions";
import Instructions from "./Components/Instructions";
import Integrations from "./Components/Integrations";
import Help from "./Components/Help";
import Sheets from "./Components/Sheets";
import ProductFolder from "./Components/ProductsFolder";
import Scans from "./Components/Scans";
import ParticularItem from "./Components/ParticularItem";
import BigCharts from "./Components/Widgets/Partials/bigCharts";
import History from "./Components/History";
const accessToken = localStorage.getItem("accessToken");
function App() {
  const { searchValue, setSearchValue } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();
  useEffect(() => {
    const pathname = window.location.pathname;
    if (
      pathname != "/login" &&
      pathname != "/signup" &&
      pathname != "/forgot-password" &&
      pathname != "/reset-password" &&
      pathname != "/email-verify"
    ) {
      if (!accessToken) {
        window.location.href = "/login";
      }
    }
  }, []);
  const lastAsinSearch = localStorage.getItem("lastASINSearch");
  useEffect(() => {
    if (lastAsinSearch) {
      setSearchValue(lastAsinSearch);
    }
  }, [lastAsinSearch]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/reset-password" element={<ConfirmPassword />} />
      <Route path="/email-verify" element={<EmailVerify />} />
      <Route path="/authorize" element={<Authorize />} />
      <Route path="/plan" element={<Plan />} />
      <Route element={<PrivateComponent />}>
        <Route element={<Main />}>
          <Route path="/" element={<Widgets searchResult={searchValue} />} />
          <Route path="/panels" element={<Panels />} />
          <Route path="/details" element={<DetailsTable />} />
          <Route path="/subscriptions" element={<Subscription />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/history" element={<History />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/help" element={<Help />} />
          <Route path="/sheets" element={<Sheets />} />
          <Route path="/product-folder" element={<ProductFolder />} />
          <Route path="/scans" element={<Scans />} />
          <Route
            path="/widgets"
            element={<Widgets searchResult={searchValue} />}
          />
          <Route
            path="/product-details/:id/:code/:time/:file/:enablePowerUpData"
            element={<ParticularItem />}
          />
          <Route
            path="/view-charts"
            element={<BigCharts searchResult={searchValue} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
