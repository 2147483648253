import React, { useRef, useState } from "react";
import { Form, Container, Row, Col, FormLabel, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { registeration } from "../Service/services";
import LoginSlider from "../Components/LoginSlider";
import "./index.css";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleAuth from "./googleAuth";

import toast from "react-hot-toast";
type Inputs = {
  email: string;
  password: string;
  userName: string;
};
const Register: React.FunctionComponent = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<any>(false);
  const [isCustomError, setIsCustomError] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const onCaptchaChange = (token: any) => {
    setCaptchaToken(token);
    setCaptchaError(false);
  };
  const onSubmit: SubmitHandler<Inputs> = async (data: any, event: any) => {
    setIsCustomError(false);
    event.preventDefault();
    try {
      if (!captchaToken) {
        setCaptchaError(true);
        return;
      }
      setLoading(true);

      const object = {
        fullName: data.userName,
        email: data.email,
        password: data.password,
        EmailConfirmationRedirectUrl: `${process.env.REACT_APP_SERVER_URL}/email-verify?email=${data.email}&token=`,
      };
      const response = await registeration(object);
      if (response.status === 200) {
        toast.success(
          "Account created successfully! Please check your email to verify your account."
        );
        reset();
        setCaptchaToken(null);
        setCaptchaError(false);
        recaptchaRef.current?.reset();
      } else {
        setIsCustomError("Try again");
      }
    } catch (error: any) {
      setIsCustomError(error?.response?.data?.ErrorMessage);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isGoogleLoading ? (
        <div className="ranks-loader">
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <></>
      )}
      <Container fluid>
        <Row>
          <Col lg={5} md={12} xs={12} className="p-0">
            <LoginSlider />
          </Col>
          <Col
            lg={7}
            md={12}
            xs={12}
            className="p-0 d-flex justify-content-center align-items-center"
          >
            <div className="login-form-section">
              <div className="login-form-content">
                <h1 className="mb-2">
                  Sign Up at <span> SellerScout</span>
                </h1>
                <p>Empower your experience, Sign up for a account today</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {isCustomError ? (
                  <span className="error-message text-danger sapn-text-error">
                    {isCustomError}
                  </span>
                ) : null}

                <div className="mb-0 password-cont">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <FormLabel className="label-text">Full Name*</FormLabel>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username"
                      className="form-input-text"
                      {...register("userName", {
                        required: "*Please enter username",
                      })}
                    />
                    {errors.userName && (
                      <span className="error-message text-danger sapn-text-error">
                        {errors.userName.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <Form.Group className="mb-3">
                  <FormLabel className="label-text">Work email*</FormLabel>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="form-input-text"
                    {...register("email", {
                      required: "*Please enter your email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid @ email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error-message text-danger sapn-text-error mt-1">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>
                <div className="mb-0 password-cont">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <FormLabel className="label-text">Password*</FormLabel>
                    <Form.Control
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Enter password"
                      className="form-input-text"
                      {...register("password", {
                        required: "*Please enter your password",
                        pattern: {
                          value:
                            /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?])[A-Za-z\d!@#$%^&*()_+{}|:"<>?]{12,}$/,
                          message:
                            "Password must be at least 12 characters with one uppercase , one lowercase , one special character, and one digit",
                        },
                      })}
                    />
                    <button
                      className="show-password"
                      type="button"
                      onClick={togglePasswordVisibility}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          togglePasswordVisibility();
                        }
                      }}
                      aria-label={
                        isPasswordVisible ? "Hide password" : "Show password"
                      }
                      style={{ padding: "0px", border: "none" }}
                    >
                      <FontAwesomeIcon
                        icon={isPasswordVisible ? faEyeSlash : faEye}
                      />
                    </button>

                    {errors.password && (
                      <span className="error-message text-danger sapn-text-error">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div style={{ position: "relative" }} className="pt-2">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                    onChange={onCaptchaChange}
                  />
                  {captchaError && (
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        fontSize: "0.9em",
                      }}
                    >
                      Please complete the CAPTCHA
                    </span>
                  )}
                </div>

                <div className="mt-4">
                  <button type="submit" className="login-btn">
                    {!loading ? (
                      "Create account"
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        aria-hidden="true"
                        className="loader"
                      />
                    )}
                  </button>
                </div>
              </Form>
              <p className="py-3 text-center mb-0 font-[500] text-[16px]">OR</p>
              <GoogleAuth
                isSignUp={true}
                setIsGoogleLoading={setIsGoogleLoading}
              />
              <p className="create-account text-center pt-1">
                Already have an account?&nbsp;{" "}
                <button
                  type="button"
                  className="sign-in-link"
                  onClick={() => navigate("/")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      navigate("/");
                    }
                  }}
                  aria-label="Sign in"
                  style={{ padding: "0px", border: "none" }}
                >
                  Sign In
                </button>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
