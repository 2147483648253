import { Navigate, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

function PrivateComponent() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<any>("");

  useEffect(() => {
    const checkAuth = () => {
      const auth = localStorage.getItem("accessToken");
      setIsAuthenticated(auth);
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateComponent;
