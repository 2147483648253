import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../Button";
import DraggableInputs from "../Draggableinput";
import "./index.css";
import { SearchContext } from "../../context/searchContext";
import {
  amazonCredentials,
  getAppConfig,
  getUserProfile,
  UpdateEmail,
  UpdateFullName,
  UpdatePassword,
  UpdateProfile,
  userDetails,
} from "../../Service/services";
import Loader from "../Loader";
import moment from "moment";
import PasswordReset from "./passwordReset";
import toast from "react-hot-toast";
import { IoMdAdd } from "react-icons/io";
import AddMarketplaceModal from "./addMarketPlace";
import NameChangeModal from "./nameChangeModal";
import EmailChangeModal from "./emailChangeModal";
import ProfileUpdateModal from "./profileUpdate";
import Flag_of_Europe from "../../Assests/Flag_of_Europe.svg.png";
import north_america from "../../Assests/north-america.png";
import canada from "../../Assests/canada.png";
import Mexico from "../../Assests/Mexico.png";
import United_States from "../../Assests/United_States.png";
const Panels: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userDetail, setUserDetail] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [addMarketplaceModal, setAddMarketplaceModal] =
    useState<boolean>(false);
  const [nameModalShow, setNameModalShow] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [fullNameLoading, setFullNameLoading] = useState<boolean>(false);
  const [emailModalShow, setEmailModalShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [appConfigResponse, setAppConfigResponse] = useState<any>([]);
  const [userProfileModalShow, setUserProfileModalShow] =
    useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string>("");
  const [userProfileError, setUserProfileError] = useState<boolean>(false);
  const [userProfileLoading, setUserProfileLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
  }>({});
  const amazonCredentialModal = localStorage.getItem("AmazonCredentialsModal");
  const [amazonCredential, setAmazonCredential] = useState<any>([]);
  const userData = JSON.parse(localStorage.getItem("userProfile") ?? "{}");
  const handleNameModalClose = () => {
    setNameModalShow(false);
    setFullName("");
    setFullNameError(false);
    setFullNameLoading(false);
  };
  const handleEmailModalClose = () => {
    setEmailModalShow(false);
    setEmail("");
    setEmailError(false);
    setEmailLoading(false);
  };
  const handleProfileModalClose = () => {
    setUserProfileModalShow(false);
    setUserProfile("");
    setUserProfileError(false);
    setEmailLoading(false);
  };
  const handleAddMarketplaceModalCLose = () => {
    setAddMarketplaceModal(false);
  };
  const {
    setSearchValue,
    setIsGobalFilter,
    setProfileImage,
    setUserFullName,
    setUserEmail,
  } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();
  useEffect(() => {
    setSearchValue("");
    setIsGobalFilter("");
  }, []);
  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      const response = await userDetails();
      const object = {
        ...userData,
        fullname: response?.data?.fullName,
        email: response?.data?.email,
      };
      const userProfile = JSON.stringify(object);
      localStorage.setItem("userProfile", userProfile);
      setUserFullName(response?.data?.fullName);
      setUserEmail(response?.data?.email);
      if (response?.data?.photo) {
        const getUserDetailsResult = await getUserProfile(
          response?.data?.photo
        );
        const contentType =
          getUserDetailsResult.headers["content-type"] || "image/jpeg";
        const blob = new Blob([getUserDetailsResult.data], {
          type: contentType,
        });
        const url = URL.createObjectURL(blob);
        setProfileImage(url);
      }
      const appConfigResponse = await getAppConfig();
      if (appConfigResponse.data) {
        const updatedEndpoints = appConfigResponse?.data?.spapiEndpoints?.map(
          (endpoint: any) => {
            if (endpoint.region === "NA") {
              return {
                ...endpoint,
                image: north_america,
                title: "North America",
              };
            } else if (endpoint.region === "EU") {
              return { ...endpoint, image: Flag_of_Europe, title: "Europe" };
            }
            return endpoint;
          }
        );
        setAppConfigResponse(updatedEndpoints);
      }

      setUserDetail(response.data);
    } catch (error: any) {
      console.log("e", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleModalShow = () => {
    setShowModal(true);
  };
  const getAmazonCredetials = async () => {
    try {
      const response = await amazonCredentials();

      const updateResonse = response.data?.map((endpoint: any) => {
        if (endpoint.countryName == "US") {
          return {
            ...endpoint,
            image: United_States,
          };
        } else if (endpoint.countryName == "Canada") {
          return {
            ...endpoint,
            image: canada,
          };
        } else if (endpoint.countryName == "Mexico") {
          return {
            ...endpoint,
            image: Mexico,
          };
        }
      });
      setAmazonCredential(updateResonse);
    } catch (e) {
      console.log("e", e);
    }
  };

  const validate = () => {
    const errors: { [key: string]: string } = {};
    // Validate oldPassword
    if (!oldPassword) {
      errors.oldPassword = "Old password is required.";
    } else if (!/[A-Z]/.test(oldPassword)) {
      errors.oldPassword =
        "Old password must contain at least one uppercase letter.";
    } else if (!/\d/.test(oldPassword)) {
      errors.oldPassword = "Old password must contain at least one number.";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(oldPassword)) {
      errors.oldPassword =
        "Old password must contain at least one special character.";
    }

    // Validate newPassword
    if (!newPassword) {
      errors.newPassword = "New password is required.";
    } else if (newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters.";
    } else if (!/[A-Z]/.test(newPassword)) {
      errors.newPassword =
        "Password must contain at least one uppercase letter.";
    } else if (!/\d/.test(oldPassword)) {
      errors.newPassword = "Password must contain at least one number.";
    }

    // Validate confirmPassword
    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your password.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleInputChange = (field: string, value: string) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));

    if (field === "oldPassword") {
      setOldPassword(value);
    } else if (field === "newPassword") {
      setNewPassword(value);
    } else if (field === "confirmPassword") {
      setConfirmPassword(value);
    }
  };
  const modelClose = () => {
    setShowModal(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const handleUpdate = async () => {
    try {
      if (validate()) {
        setLoading(true);
        const object = {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
        await UpdatePassword(object);
        toast.success("Password updated successfully!");
        modelClose();
      }
    } catch (e: any) {
      if (e.response.data.ErrorMessage == "password is incorrect") {
        toast.error("Old password is incorrect");
      } else {
        toast.error(e.response.data.ErrorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateName = async () => {
    try {
      if (!fullName) {
        setFullNameError(true);
        return;
      }
      setFullNameLoading(true);
      const object = {
        fullName: fullName,
      };
      await UpdateFullName(object);
      toast.success("Full name updated successfully.");
      setNameModalShow(false);
      setFullName("");
      getUserDetails();
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setFullNameLoading(false);
    }
  };
  const handleUpdateEmail = async () => {
    try {
      if (!email) {
        setEmailError(true);
        return;
      }
      setEmailLoading(true);
      const object = {
        email: email,
      };
      await UpdateEmail(object);
      toast.success("Email updated successfully.");
      setEmailModalShow(false);
      setEmail("");
      getUserDetails();
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setEmailLoading(false);
    }
  };
  const handleUpdateProfile = async () => {
    try {
      if (!userProfile) {
        setUserProfileError(true);
        return;
      }
      setUserProfileLoading(true);
      const formData = new FormData();
      formData.append("uploadAvatar", userProfile);
      await UpdateProfile(formData);
      toast.success("Profile updated successfully.");
      getUserDetails();
      setUserProfileModalShow(false);
      setUserProfile("");
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setUserProfileLoading(false);
    }
  };
  useEffect(() => {
    getUserDetails();
    getAmazonCredetials();
  }, []);
  useEffect(() => {
    if(amazonCredentialModal && appConfigResponse?.length >0){
      setAddMarketplaceModal(true)
      localStorage.removeItem("AmazonCredentialsModal")
    }
  },[amazonCredentialModal,appConfigResponse])
  return (
    <section className="px-4 panels-section pb-5">
      {isLoading ? (
        <div className="loading-loader">
          <Loader />
        </div>
      ) : (
        <>
          <Row>
            <Col>
              <div className="panels-container  ">
                <div className="panels-item border-panel">
                  <div className="panels-title d-flex justify-content-between align-items-center ">
                    <h2 className="mb-0"> User Details</h2>
                  </div>
                  <div className="panels-content ">
                    {/* <Form> */}
                    <Row className="d-flex gap-3 px-2 py-2 ">
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group-panel btn-input">
                          <Form.Label className=""> Full Name</Form.Label>
                          <input
                            type="text"
                            className=""
                            placeholder="mosesalhakim"
                            maxLength={50}
                            value={userDetail.fullName}
                            readOnly
                          />
                          <div className="form-btns-panels user-detail-btn">
                            <Button
                              buttonText={"Change"}
                              buttonColor={"#155EEF1A"}
                              buttonWidth="70px"
                              onClick={() => setNameModalShow(true)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group-panel btn-input">
                          <Form.Label className="">Email</Form.Label>
                          <input
                            type="email"
                            className=""
                            value={userDetail.email}
                            placeholder="mosesalhakim@gmail.com"
                            maxLength={50}
                            readOnly
                          />
                          <div className="form-btns-panels user-detail-btn">
                            <Button
                              buttonText={"Change"}
                              buttonColor={"#155EEF1A"}
                              buttonWidth="70px"
                              onClick={() => setEmailModalShow(true)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group-panel btn-input">
                          <Form.Label className="">Profile Pic</Form.Label>
                          <input
                            type="text"
                            className=" "
                            placeholder="Profile Pic"
                            maxLength={50}
                            readOnly
                          />
                          <div className="form-btns-panels">
                            <Button
                              buttonText={"Change"}
                              buttonColor={"#155EEF1A"}
                              buttonWidth="70px"
                              onClick={() => setUserProfileModalShow(true)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group-panel btn-input">
                          <Form.Label className="">Password</Form.Label>
                          <input
                            type="Password"
                            className=" "
                            placeholder="******"
                            maxLength={50}
                            readOnly
                          />
                          <div className="form-btns-panels user-detail-btn">
                            <Button
                              buttonText={"Reset"}
                              buttonColor={"#155EEF1A"}
                              buttonWidth="70px"
                              onClick={handleModalShow}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12} xs={12}>
                        <Form.Group className="form-group-panel">
                          <Form.Label className="">User Since</Form.Label>
                          <input
                            type="text"
                            className=" "
                            placeholder="Token"
                            maxLength={50}
                            value={
                              userDetail?.createdOn
                                ? moment(userDetail.createdOn).format(
                                    "MM-DD-YYYY"
                                  )
                                : ""
                            }
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* </Form> */}
                  </div>
                </div>
                <div className="panels-item border-panel">
                  <div className="panels-title d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Amazon Credentials</h2>
                  </div>
                  <div className="panels-content marketplace-panel d-flex flex-column justify-content-center align-items-center p-2 ">
                    <button
                      className="add-marketplace-btn"
                      style={{ width: "200px" }}
                      onClick={() => setAddMarketplaceModal(true)}
                    >
                      <IoMdAdd className="me-2" size={22} color="#155EEF" /> Add
                      Marketplace
                    </button>
                    <div className="d-flex mt-4 gap-md-5">
                      {amazonCredential?.map((item: any, index: any) => {
                        return (
                          <div key={`${item}-${index}`}>
                            <img
                              src={item.image}
                              alt=""
                              style={{
                                width: "160px",
                                borderRadius: "10px",
                                height: "90px",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {/* VAT Section */}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0"> VAT</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel'>
                                                <Form.Label>VAT Scheme</Form.Label>
                                                <Form.Control as="select" defaultValue="No Registered" className="custom-panels-select">
                                                    <option value="No.Registered">No Registered</option>
                                                    <option value="Registered">Registered</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Form>

                            </div>
                        </div>
                    </div> */}
              {/* Buying Criteria */}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0"> Buying Criteria</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Minimum BSR (%)</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="percentage-btn">%</button>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Minimum BSR (%)</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="2.00"
                                                    maxLength={50}
                                                />
                                                <button className="percentage-btn">%</button>


                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input dollar-input'>
                                                <Form.Label className=''>Minimum Profit</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="dollar-btn">$</button>

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Minimum ROI</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="percentage-btn">%</button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </div>
                    </div> */}
              {/* Additional Costs*/}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Additional Costs</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2" >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input dollar-input'>
                                                <Form.Label className=''>Prep Fee</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="dollar-btn">$</button>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input dollar-input'>
                                                <Form.Label className=''>Misc Fee</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="dollar-btn">$</button>

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Misc Fee(%)</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="percentage-btn">%</button>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input dollar-input'>
                                                <Form.Label className=''>Inbound Shipping</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="dollar-btn">$</button>
                                                <button className="storage-btn">Per Pound</button>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel '>
                                                <Form.Label>Inbound Placement (.com only)</Form.Label>
                                                <Form.Control as="select" defaultValue="Amazon.com" className="custom-panels-select">
                                                    <option value="Amazon.com">Amazon Optimized Splits</option>
                                                    <option value="eBay.com">eBay.com</option>
                                                    <option value="Etsy.com">Etsy.com</option>
                                                    <option value="Walmart.com">Walmart.com</option>
                                                    <option value="Target.com">Target.com</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Use Peak Storage Fees</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="No"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Enabled CEP Storage</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
              {/* Default Values*/}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0"> Default Values</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel '>
                                                <Form.Label>Ranks & Prices Time Frame </Form.Label>
                                                <Form.Control as="select" defaultValue="Amazon.com" className="custom-panels-select">
                                                    <option value="Amazon.com">Current</option>
                                                    <option value="eBay.com">Current</option>
                                                    <option value="Etsy.com">Current</option>

                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel '>
                                                <Form.Label>Buy Box Analysis Time Frame </Form.Label>
                                                <Form.Control as="select" defaultValue="Amazon.com" className="custom-panels-select">
                                                    <option value="Amazon.com">90 Days </option>
                                                    <option value="eBay.com">60 Days</option>
                                                    <option value="Etsy.com">30 Days</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input dollar-input'>
                                                <Form.Label className=''>FBM Cost </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="dollar-btn">$</button>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Storage Time </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0"
                                                    maxLength={50}
                                                />
                                                <button className="storage-btn">Months</button>

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Local Fullfillment </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="FBA"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>European Fullfillment </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="EFN"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>

                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Custom ROI Calc </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="0.00"
                                                    maxLength={50}
                                                />
                                                <button className="percentage-btn">% </button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
              {/* Miscellaneous */}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0"> Miscellaneous</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Top Offers on Search Results </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input'>
                                                <Form.Label className=''>Keepa on Search Results  </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Store Geo Location  </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Dark Mode (Beta) </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="No"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
              {/*    Quick Info */}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Quick Info</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Profit</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Profit Margin</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show ROI </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Breakeven </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Offer Summary </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
              {/*   Charts */}
              {/* <div className="panels-container ">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Charts</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2 " >
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Prices Chart </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Sold Chart</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Show Offers Chart </Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder="Yes"
                                                    maxLength={50}
                                                />
                                                <FormControlLabel control={<Switch defaultChecked />} label="" className="switch-panels" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel '>
                                                <Form.Label>Charts Time Span  </Form.Label>
                                                <Form.Control as="select" defaultValue="Amazon.com" className="custom-panels-select">
                                                    <option value="Amazon.com">All Time</option>
                                                    <option value="eBay.com">Current</option>
                                                    <option value="Etsy.com">Current</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
              {/*   Tags */}
              {/* <div className="panels-container d-none">
                        <div className="panels-item">
                            <div className="panels-title d-flex justify-content-between align-items-center">
                                <h2 className="mb-0">Tags</h2>
                            </div>
                            <div className="panels-content ">
                                <Form>
                                    <Row className="d-flex gap-1 px-2 py-2">
                                        <Col lg={12} md={12} xs={12}>
                                            <Form.Group className='form-group-panel btn-input '>
                                                <Form.Label className=''>Add Tag</Form.Label>
                                                <input
                                                    type="text"
                                                    className=' '
                                                    placeholder=""
                                                    maxLength={50}
                                                />
                                                <div className="form-btns-panels">
                                                    <Button buttonText={"New Tag"} buttonColor={"#FBBC31"} buttonWidth="96px" />
                                                </div>
                                                <div className="star-group">
                                                    <div className="star-box">
                                                        <FaStar className="star-icon" />
                                                    </div>
                                                    <button className="star-btn">Sdfsfs</button>
                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div> */}
            </Col>
            {/* SAS  Panels Section  */}
            <Col>
              <div className="panels-container sas-drags-panels ">
                <div className="sas-panels-item panels-item ">
                  <div className="panels-title d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Panels</h2>
                  </div>
                  <div className="panels-content ">
                    <Form className="sas-panels-form">
                      <Row className="d-flex gap-2 px-2 py-2  sas-panels">
                        <DraggableInputs />
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-3 panels-btn">
            <Button
              buttonText={"Cancel"}
              buttonColor="transparent"
              borderColor="#757575"
              fontColor="#181D27"
              buttonWidth="100px"
            />
            <Button
              buttonText={"Save"}
              buttonColor={"#155EEF"}
              borderColor="#155EEF"
              fontColor="#FFFFFF"
              buttonWidth="100px"
            />
          </div>
        </>
      )}
      <PasswordReset
        showModal={showModal}
        modelClose={modelClose}
        oldPassword={oldPassword}
        confirmPassword={confirmPassword}
        loading={loading}
        errors={errors}
        newPassword={newPassword}
        handleUpdate={handleUpdate}
        handleInputChange={handleInputChange}
      />
      <AddMarketplaceModal
        addMarketplaceModal={addMarketplaceModal}
        handleAddMarketplaceModalCLose={handleAddMarketplaceModalCLose}
        appConfigResponse={appConfigResponse}
      />
      <NameChangeModal
        handleNameModalClose={handleNameModalClose}
        nameModalShow={nameModalShow}
        fullNameError={fullNameError}
        fullName={fullName}
        setFullName={setFullName}
        fullNameLoading={fullNameLoading}
        handleUpdateName={handleUpdateName}
      />
      <EmailChangeModal
        handleEmailModalClose={handleEmailModalClose}
        emailModalShow={emailModalShow}
        emailError={emailError}
        email={email}
        setEmail={setEmail}
        emailLoading={emailLoading}
        handleUpdateEmail={handleUpdateEmail}
      />
      <ProfileUpdateModal
        handleProfileModalClose={handleProfileModalClose}
        userProfileModalShow={userProfileModalShow}
        userProfileError={userProfileError}
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        userProfileLoading={userProfileLoading}
        handleUpdateProfile={handleUpdateProfile}
      />
      {/* <Banner bannerText={"Use of Google Chrome and the SAS Chrome Extension are required for full functionality and all features in the SAS Web App. install the SAS "} bannerlink={"Chrome Extension."} /> */}
    </section>
  );
};

export default Panels;
