import React from "react";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../../helpers";
import {
  countryTitleValue,
  getDiscountBtnClass,
  getDiscountClass,
} from "../../../helpers/commanFunction";

interface KeepaListProps {
  data?: any;
  className?: string;
  type?: any;
  selectedItem?: any;
  onItemSelect?: (item: any) => void;
}

const KeepaList = ({
  data,
  className,
  type,
  selectedItem,
  onItemSelect,
}: KeepaListProps) => {
  const classes = useStyles();

  const handleClick = (item: any) => {
    if (onItemSelect) {
      if (type === "country") onItemSelect(item?.key);
      else {
        onItemSelect(item);
      }
    }
  };

  return (
    <div>
      <ul
        className={
          className ??
          "d-flex gap-2 align-items-center offers-contentsss justify-content-start flex-wrap"
        }
      >
        {data.map((item: any, index: any) => (
          <li key={`${index}-${index}`} className="list-name">
            {type !== "country" ? (
              <Tooltip
                title={countryTitleValue(item)}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
                componentsProps={{
                  tooltip: {
                    className: "content-tooltip",
                  },
                }}
              >
                <button
                  className={`discount-btn-refresh ${getDiscountClass(
                    type,
                    selectedItem,
                    item?.key,
                    item
                  )}
              `}
                  onClick={() => handleClick(item)}
                >
                  {item}
                </button>
              </Tooltip>
            ) : (
              <button
                className={`btnn ${getDiscountBtnClass(
                  type,
                  selectedItem,
                  item?.key,
                  item
                )}
              `}
                onClick={() => handleClick(item)}
              >
                <img src={item.src} alt="" />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default KeepaList;
