import React from "react";
import { Accordion } from "react-bootstrap";
import { FaChartPie, FaShoppingCart,FaBalanceScale,FaTag,FaPlusCircle,FaRegEye } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { FaLocationDot,FaAmazon,FaBell,FaChartLine } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { TiWorld } from "react-icons/ti";
import { PiSquaresFourFill } from "react-icons/pi";
import { Tooltip } from "@mui/material";
import { useStyles } from "../../helpers";
import AlertBoxes from "../AlertBox/AlertBoxes";

interface WidgetProps  {
  icon: any;
  title: any;
  activeKey: any;
  setActiveKey: React.Dispatch<React.SetStateAction<any>>;
  handleToggle: any;
  children: any;
  data?: any;
  alerts?: any;
  variations?: any;
  className?: string;
}

const Widget = ({
  icon,
  title,
  activeKey,
  setActiveKey,
  handleToggle,
  children,
  data,
  alerts,
  variations,
  className,
}: WidgetProps ) => {
  const classes = useStyles();
  const getActiveKey = (): string => {
    const titleKeyMap: Record<string, keyof typeof activeKey> = {
      "Offers": "offers",
      "Buy Box Analysis": "buyBoxAnalysis",
      "Seller Central": "sellerCentral",
      "Vat Settings": "vatSettings",
      "European Marketplaces": "europeanMarketPlaces",
      "Lookup Details": "lookupDetails",
      "Variations": "variations",
      "R.O.I.": "roi",
      "Geo location": "geolocation",
      "Keepa": "keepa",
      "Search Again": "search",
      "Notes & Tags": "notesTags",
      "eBay": "eBay",
      "Alerts": "alerts",
      "Discounts": "discounts",
      "Charts": "charts",
    };
  
    const key = titleKeyMap[title];
    if (key && activeKey?.[key] !== undefined) {
      return activeKey[key] ? "0" : "1";
    }
  
    return "1";
  };

  const onHeaderClick = () => {
    let key = null;
    switch (title) {
      case "eBay":
        key = "eBay";
        break;
      case "Offers":
        key = "offers";
        break;
      case "Seller Central":
        key = "sellerCentral";
        break;
      case "Search Again":
        key = "search";
        break;
      case "Notes & Tags":
        key = "notesTags";
        break;
      case "Geo location":
        key = "geolocation";
        break;
      case "Vat Settings":
        key = "vatSettings";
        break;
      case "Discounts":
        key = "discounts";
        break;
      case "European Marketplaces":
        key = "europeanMarketPlaces";
        break;
      case "R.O.I.":
        key = "roi";
        break;
      case "Lookup Details":
        key = "lookupDetails";
        break;
      case "Alerts":
        key = "alerts";
        break;
      case "Keepa":
        key = "keepa";
        break;
      case "Variations":
        key = "variation";
        break;
      case "Buy Box Analysis":
        key = "buyBoxAnalysis";
        break;
      case "Charts":
        key = "charts";
        break;
      default:
        break;
    }
    if (key) {
      handleToggle(key);
    }
  };
  return (
    <Accordion activeKey={getActiveKey()} className="position-relative">
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={onHeaderClick} >
          <div className="d-flex gap-1">
            {icon === "shoppingcart" && (
              <FaShoppingCart className="offer-cart none-icon" />
            )}
            {icon === "amazon" && <FaAmazon className="offer-cart none-icon" />}
            {icon === "search" && <CiSearch className="offer-search none-icon" />}
            {icon === "e" && (
              <span className="me-2 none-icon">
                <b>e</b>
              </span>
            )}
            {icon === "keepa" && <FaPlusCircle className="offer-search none-icon" />}
            {icon === "alert" && <FaBell className="offer-search none-icon" />}
            {icon === "flag" && <FaTag className="offer-cart none-icon" />}
            {icon === "location" && <FaLocationDot className="offer-cart none-icon" />}
            {icon === "scale" && <FaBalanceScale className="offer-search none-icon" />}
            {icon === "roi" && <SlCalender className="offer-search none-icon" />}
            {icon === "discounts" && (
              <span className="me-2 none-icon">
                <b>%</b>
              </span>
            )}
            {icon === "market" && <TiWorld className="offer-search none-icon" />}
            {icon === "eye" && <FaRegEye className="offer-search none-icon" />}
            {icon === "blocks" && (
              <PiSquaresFourFill className="offer-search none-icon" />
            )}
            {icon === "piechart" && <FaChartPie className="offer-search none-icon" />}
            {icon === "charts" && <FaChartLine className="offer-search none-icon" />}
            {title}
          </div>
          {icon === "alert" && (
            <div className="d-flex justify-content-end w-100 pe-2">
              <AlertBoxes alertLevels={alerts?.alertLevel} />
            </div>
          )}
          {variations?.length > 0 && (
            <>
              {icon === "blocks" && (
                <div className="d-flex justify-content-end w-100 pe-2">
                  <div
                    style={{
                      backgroundColor: "#FFF",
                      borderColor: "#D4D6D8",
                      color: "#414651",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "100px",
                      width: "35px",
                      height: "22px",
                      fontSize: "12px",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {variations?.length}
                  </div>
                </div>
              )}
            </>
          )}
          {data?.alerts?.variations && title === "Alerts" ? (
            <div className="d-flex justify-content-end align-items-end flex-row w-100">
              <Tooltip
                title={
                  <>
                    Amazon Share Buy Box:
                    {data?.alerts?.amazonShareBuyBox
                      ? data?.alerts?.amazonShareBuyBox
                      : " - "}
                    <br /> IP Analysis
                    {data?.alerts?.ipAnalysis
                      ? data?.alerts?.ipAnalysis
                      : " - "}
                    <br /> Size:
                    {data?.alerts?.size ? data?.alerts?.size : " - "} <br />
                    Meltable: {data?.alerts?.meltable ? " Yes " : " No "}
                    <br />
                    Low Price Fee: -
                  </>
                }
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div className="styled-box warning-background">5</div>
              </Tooltip>
              <Tooltip
                title={
                  <div>
                    {"Variations: This listing has " +
                      data?.alerts?.variations +
                      " variations"}
                  </div>
                }
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div className="styled-box warning-background">
                  {data?.alerts?.variations}
                </div>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </Accordion.Header>
        <Accordion.Body className={className ?? ""}>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Widget;
