import React, { useState } from "react";
import { Form, Container, Row, Col, Spinner, FormLabel } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { login, resendEmail } from "../Service/services";
import LoginSlider from "../Components/LoginSlider";
import "./index.css";
import toast from "react-hot-toast";
import GoogleAuth from "./googleAuth";

import Alert from "react-bootstrap/Alert";
type Inputs = {
  email: string;
  password: string;
};

const Login: React.FunctionComponent = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const togglePasswordVisibility = (
    event: React.MouseEvent<HTMLSpanElement>
  ) => {
    event.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: any, event: any) => {
    event.preventDefault();
    setLoading(true);
    setIsUserVerified(false);
    try {
      setEmail(data?.email);
      const response = await login(data);
      if (response.status === 200) {
        if (response.data.haveActiveSubscription) {
          const accessTokenId = response?.data?.accessToken;
          localStorage.setItem(
            "lastASINSearch",
            response.data?.analyzer.lastASINSearch
          );
          const userDetails = {
            ...response.data.userProfile,
            fullname: response.data?.userInfo?.fullName,
            ...response.data?.plan,
          };
          const userProfile = JSON.stringify(userDetails);
          localStorage.setItem("userProfile", userProfile);
          if (response.data?.userInfo?.photo) {
            localStorage.setItem(
              "ProfilePicture",
              response.data?.userInfo?.photo
            );
          }
          localStorage.setItem("accessToken", accessTokenId);
          if (response.data?.credentials?.haveAmazonCredentials) {
            navigate('/');
          } else {
            window.location.href = "/panels";
            localStorage.setItem(
              "AmazonCredentialsModal",
              response.data?.credentials?.haveAmazonCredentials
            );
          }
        } else {
          navigate("/plan");
        }
      }
    } catch (error: any) {
      if (
        error?.response?.data?.ErrorMessage == "Email address not verified."
      ) {
        setIsUserVerified(true);
      } else {
        toast.error(
          error?.response?.data?.message ||
            "Invalid credentials. Please try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmailVerification = async () => {
    try {
      const object = {
        email: email,
        emailConfirmationRedirectUrl: `${process.env.REACT_APP_SERVER_URL}/email-verify?email=${email}&token=`,
      };
      const response = await resendEmail(object);
      console.log(response);
      toast.success("Email sent successfully!");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isGoogleLoading ? (
        <div className="ranks-loader">
          <Spinner animation="border">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <></>
      )}

      <Container fluid>
        <Row>
          <Col lg={5} md={12} xs={12} className="p-0">
            <LoginSlider />
          </Col>
          <Col
            lg={7}
            md={12}
            xs={12}
            className="p-0 d-flex justify-content-center align-items-center"
          >
            <div className="login-form-section">
              <div className="login-form-content">
                <h1 className="mb-2">
                  Sign in at <span>SellerScout</span>
                </h1>
                <p>Empower your experience, sign in for a account today</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <FormLabel className="label-text">Work email*</FormLabel>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    className="form-input-text"
                    {...register("email", {
                      required: "*Please enter your email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="error-message text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </Form.Group>
                <div className="mb-0 password-cont">
                  <Form.Group
                    className="mb-3 relative"
                    controlId="formBasicPassword"
                  >
                    <FormLabel className="label-text">Password*</FormLabel>
                    <Form.Control
                      type={isPasswordVisible ? "text" : "password"}
                      placeholder="Enter your password"
                      className="form-input-text"
                      {...register("password", {
                        required: "*Please enter your password",
                        pattern: {
                          value:
                            /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?])[A-Za-z\d!@#$%^&*()_+{}|:"<>?]+$/,
                          message: "Password is incorrect",
                        },
                      })}
                    />
                    <button
                      className="show-password"
                      type="button"
                      onClick={togglePasswordVisibility}
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter" || e.key === " ") {
                          togglePasswordVisibility(e);
                        }
                      }}
                      aria-label={
                        isPasswordVisible ? "Hide password" : "Show password"
                      }
                      style={{ padding: "0px", border: "none" }}
                    >
                      <FontAwesomeIcon
                        icon={isPasswordVisible ? faEyeSlash : faEye}
                      />
                    </button>

                    {errors.password && (
                      <span className="error-message text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end align-items-end mt-2 mb-3">
                  <button
                    className="forgot-password"
                    type="button"
                    onClick={() => navigate("/forgot-password")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        navigate("/forgot-password");
                      }
                    }}
                    aria-label="Forgot Password?"
                    style={{ padding: "0px", border: "none" }}
                  >
                    Forgot Password?
                  </button>
                </div>
                {isUserVerified ? (
                  <Alert
                    key={"danger"}
                    variant={"danger"}
                    className="alert-message"
                  >
                    Email address not verified. Please check your inbox for the
                    verification email. If you didn't receive the email, click
                    on &nbsp;{" "}
                    <button
                      className="reset-email"
                      type="button"
                      onClick={handleResendEmailVerification}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          handleResendEmailVerification();
                        }
                      }}
                      aria-label="Resend email verification"
                      style={{ padding: "0px", border: "none" }}
                    >
                      Reset Email
                    </button>.
                  </Alert>
                ) : (
                  <></>
                )}

                <div>
                  <button
                    type="submit"
                    className="login-btn"
                    disabled={loading}
                  >
                    {!loading ? (
                      "Log in"
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        aria-hidden="true"
                        className="loader"
                      />
                    )}
                  </button>
                </div>
              </Form>
              <p className="py-3 text-center mb-0 font-[500] text-[16px]">OR</p>
              <GoogleAuth
                isSignUp={false}
                setIsGoogleLoading={setIsGoogleLoading}
              />

              <p className="create-account text-center pt-1">
                Don’t have an account?&nbsp;{" "}
                <button
                  type="button"
                  className="signup-link"
                  onClick={() => navigate("/signup")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      navigate("/signup");
                    }
                  }}
                  aria-label="Sign up"
                  style={{ padding: "0px", border: "none" }}
                >
                  Sign Up
                </button>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
