import React from "react";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { RxQuestionMarkCircled } from "react-icons/rx";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const ScanSettingModal = ({
  isShowModal,
  settingModalSettingClose,
  isPrepFee,
  setIsPrepFee,
  isAmazonCheck,
  setIsAmazonCheck,
  isKeepaChartDataRange,
  setIsKeepaChartDataRange,
  handleUpdateScan,
  scanSettingLoading,
}: any) => {
  return (
    <div>
      <Modal
        show={isShowModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-filter-marketplace"
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="contained-modal-title-vcenter">
            Scan Settings
          </Modal.Title>
          <IoMdClose
            color="#fff"
            size={25}
            onClick={settingModalSettingClose}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex p-2 scan-input">
            <Col
              md={6}
              className=" d-flex justify-content-start align-items-center scan-setting-text "
            >
              Prep Fee &nbsp;
              <RxQuestionMarkCircled />
            </Col>
            <Col
              md={6}
              className=" d-flex justify-content-center align-items-center"
            >
              <input
                type="number"
                placeholder="Prep Fee"
                value={isPrepFee}
                onChange={(e: any) => setIsPrepFee(e.target.value)}
                className="prepFee-input"
              />
            </Col>
            <Col
              md={6}
              className=" d-flex justify-content-start align-items-center scan-setting-text pt-3"
            >
              Amazon Check &nbsp;
              <RxQuestionMarkCircled />
            </Col>
            <Col
              md={6}
              className=" d-flex justify-content-center align-items-center pt-3"
            >
              <select
                value={isAmazonCheck}
                onChange={(e: any) => setIsAmazonCheck(e.target.value)}
                className="prepFee-input"
              >
                <option value="Select Day">Select Day</option>
                <option value="30">30 days</option>
                <option value="90">90 days</option>
                <option value="180">180 days</option>
                <option value="360">360 days</option>
                <option value="-1">Lifetime</option>
              </select>
            </Col>
            <Col
              md={6}
              className=" d-flex justify-content-start align-items-center scan-setting-text pt-3"
            >
              Keepa Chart Date Range &nbsp;
              <RxQuestionMarkCircled />
            </Col>
            <Col
              md={6}
              className=" d-flex justify-content-center align-items-center pt-3"
            >
              <select
                value={isKeepaChartDataRange}
                onChange={(e: any) => setIsKeepaChartDataRange(e.target.value)}
                className="prepFee-input"
              >
                <option value="Select Day">Select Day</option>
                <option value="30">30 days</option>
                <option value="90">90 days</option>
                <option value="360">360 days</option>
              </select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mt-2 mb-2">
          <Button
            variant="secondary"
            onClick={settingModalSettingClose}
            disabled={scanSettingLoading}
          >
            Close
          </Button>
          <Button onClick={handleUpdateScan} disabled={scanSettingLoading}>
            {scanSettingLoading ? "Loading..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScanSettingModal;
