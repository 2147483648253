import React, { useState, useEffect, useContext } from "react";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../context/searchContext";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { IoChevronDown, IoSettingsOutline } from "react-icons/io5";
import { SellerLogo, User, searchNewIcon } from "../utils";
import { Tooltip } from "@mui/material";
import { getUserProfile, logout } from "../Service/services";
import { RiBillLine } from "react-icons/ri";
import { MdOutlineIntegrationInstructions, MdLogout } from "react-icons/md";
import { Image } from "react-bootstrap";

interface HeaderProps {
  onSearch: (searchValue: string) => void;
  isOpen?: boolean;
  toggleSidebar?: any;
}
const Header: React.FunctionComponent<HeaderProps> = ({
  onSearch,
  isOpen,
  toggleSidebar,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const getProfile = localStorage.getItem("ProfilePicture");
  const {
    searchValue,
    setSearchValue,
    setIsGobalFilter,
    isGobalFilter,
    profileImage,
    setProfileImage,
    userFullName,
    userEmail,
  } =
    useContext(SearchContext) ??
    (() => {
      throw new Error(
        "SearchContext is undefined. Ensure the component is within SearchState."
      );
    })();
  const userDetails = JSON.parse(localStorage.getItem("userProfile") ?? "{}");

  const getHandleProfile = async () => {
    try {
      if (getProfile) {
        const getUserDetailsResult = await getUserProfile(getProfile);
        const contentType =
          getUserDetailsResult.headers["content-type"] || "image/jpeg";
        const blob = new Blob([getUserDetailsResult.data], {
          type: contentType,
        });
        const url = URL.createObjectURL(blob);

        setProfileImage(url);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getHandleProfile();
  }, [getProfile]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "asin-search-input") {
      setIsGobalFilter(e.target.value);
      setError("");
      setShowTooltip(false);
    }
  };
  const logoutHandler = async () => {
    setIsLoading(true);
    try {
      const response = await logout();
      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userProfile");
        localStorage.removeItem("ProfilePicture");
        localStorage.removeItem("lastASINSearch");
        // window.location.href = "/login";
        navigate("/login");
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleSearch = () => {
    const asinInputId = "asin-search-input";
    if (validateASIN(asinInputId)) {
      const inputValue = (
        document.getElementById(asinInputId) as HTMLInputElement
      ).value;
      const baseUrl = window.location.origin;
      navigate(`/?asin=${inputValue}`);
      localStorage.setItem("ASINID", inputValue);
      localStorage.setItem("lastASINSearch", inputValue);
      setSearchValue(inputValue);
      onSearch(inputValue);
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  };
  const params = new URLSearchParams(window.location.search);
  const asinFromUrl = params.get("asin");
  useEffect(() => {
    if (asinFromUrl) {
      if (validateASINString(asinFromUrl)) {
        localStorage.setItem("ASINID", asinFromUrl);
        setSearchValue(asinFromUrl);
        localStorage.setItem("lastASINSearch", asinFromUrl);
        onSearch(asinFromUrl);
        setIsGobalFilter(asinFromUrl);
      } else {
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    } else {
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [asinFromUrl]);

  const validateASINString = (asin: string): boolean => {
    const asinRegex = /^[A-Z0-9]{10}$/;
    return asinRegex.test(asin);
  };
  const validateASIN = (id: string): boolean => {
    const inputElement = document.getElementById(id) as HTMLInputElement;

    if (!inputElement) {
      console.error(`Input element with id "${id}" not found.`);
      return false;
    }

    const inputValue = inputElement.value;

    if (inputValue.startsWith("http://") || inputValue.startsWith("https://")) {
      setError(
        "Search by URL is not implemented yet. We are working on this feature."
      );
      return false;
    }

    const asinRegex = /^[A-Z0-9]{10}$/;
    if (!asinRegex.test(inputValue)) {
      setError("Invalid ASIN. Please enter a 10-character alphanumeric code.");
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    if (isGobalFilter !== searchValue) {
      setIsGobalFilter(searchValue);
    }
  }, [searchValue]);
  return (
    <>
      <div
        className={`header d-flex justify-content-between align-items-center ${
          isOpen ? "open" : "collapsed"
        }`}
      >
        <div className="open-close-icon-display">
          {isOpen ? (
            <div className="toggle-sidebar">
              <IoIosArrowBack
                onClick={() => toggleSidebar(false)}
                className="toggle toggle-arrow"
              />
            </div>
          ) : (
            <div className="toggle-sidebar">
              <IoIosArrowForward
                onClick={() => toggleSidebar(true)}
                className="toggle toggle-arrow left-arrow-sidebar"
              />
            </div>
          )}
        </div>
        <img src={SellerLogo} className="site-logo" alt="Seller Scout Logo" />

        <div>
          <Tooltip
            title={error}
            open={showTooltip}
            placement="bottom"
            onClose={() => setShowTooltip(false)}
          >
            <div className="input-search relative ">
              <button
                className="search-icon-main"
                onClick={handleSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleSearch();
                  }
                }}
                aria-label="Search"
                style={{ border: "none", padding: "0px" }}
              >
                <img
                  src={searchNewIcon}
                  className="search-icon"
                  alt="Search icon"
                />
              </button>

              <input
                id="asin-search-input"
                className="input-search-field"
                placeholder="Search ASIN"
                value={isGobalFilter}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={handleInputChange}
              />
            </div>
          </Tooltip>
        </div>

        <div className="user-settings-right d-flex align-items-center gap-md-5 ">
          <div className="dropdown header-dropdown">
            <button
              className="profile-btn dropdown-toggle d-flex align-items-center justify-content-center profile-dropdown"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Image
                src={profileImage || User}
                alt="scan"
                height={45}
                width={44}
                className="profile-img"
              />
              <div className="profile-icon">
                <h2>
                  {(userFullName?.length > 20
                    ? userFullName.slice(0, 20) + "..."
                    : userFullName) ||
                    (userDetails?.fullname?.length > 20
                      ? userDetails.fullname.slice(0, 20) + "..."
                      : userDetails?.fullname)}
                </h2>
                <p>Member</p>
              </div>
              <IoChevronDown />
            </button>
            <div className="dropdown-menu mt-3">
              <div className=" d-flex flex-column align-items-center pt-4">
                <img
                  src={profileImage || User}
                  alt="User"
                  className="user-image"
                />
                <div className="text-center d-flex flex-column pt-2">
                  <span className="userName-h2">
                    {userFullName || userDetails?.fullname}
                  </span>
                  <span className="userEmail-hp">
                    {userEmail || userDetails?.email}
                  </span>
                </div>
              </div>
              <hr className="" />
              <div>
                <button
                  className="dropdown-item gap-3"
                  onClick={() => navigate("/panels")}
                >
                  <IoSettingsOutline size={22} />
                  <span className="pt-1">Settings</span>
                </button>
                <button
                  className="dropdown-item gap-3"
                  onClick={() => navigate("/subscriptions")}
                >
                  <RiBillLine size={22} />
                  <span className="pt-1">Subscriptions</span>
                </button>
                <button
                  className="dropdown-item gap-3"
                  onClick={() => navigate("/integrations")}
                >
                  <MdOutlineIntegrationInstructions size={22} />
                  <span className="pt-1">Integration</span>
                </button>
              </div>
              <hr className="" />
              <div>
                <button className="dropdown-item gap-3" onClick={logoutHandler}>
                  <MdLogout size={22} />
                  <span className="pt-1">Sign Out</span>
                </button>
              </div>
            </div>
          </div>

          {/* profile icon ends */}
        </div>
      </div>
      {isLoading && (
        <div className="logout-popup">
          <div className="logout-loading">
            <Loader />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
