import React, { useState, useEffect, useCallback } from "react";

import { calculateTotalFees } from "../../../helpers";

interface ProfitCalculatorProps {
  productDetails: any;
  setProductDetails: (details: any) => void;
}

export const useProfitCalculator = ({
  productDetails,
  setProductDetails,
}: ProfitCalculatorProps) => {
  const [inboundPlacementIndex, setInboundPlacementIndex] = useState(1);

  const calculateReferralFee = useCallback(() => {
    const { category, conditions, minimumFee } =
      productDetails?.feeRules?.referral ?? {};
    const salePrice = productDetails?.profitCalculator?.salePrice;

    let totalFee = 0;
    let remainingAmount = salePrice;

    if (category?.includes(productDetails?.productDetails?.category)) {
      for (let condition of conditions) {
        if (remainingAmount <= 0) break;

        let applicableAmount;
        if (condition.max === null) {
          applicableAmount = remainingAmount;
        } else {
          applicableAmount = Math.min(
            remainingAmount,
            condition.max - condition.min
          );
        }

        totalFee += applicableAmount * condition.rate;
        remainingAmount -= applicableAmount;
      }
    } else {
      totalFee =
        (salePrice * productDetails?.profitCalculator?.referralFeePercentage) /
        100;
    }

    return Math.max(totalFee, minimumFee || 0).toFixed(2);
  }, [productDetails]);

  const calculateFBAFee = useCallback(() => {
    const fbaFeeRules = productDetails?.feeRules?.fbaFee ?? [];
    const salePrice = productDetails?.profitCalculator?.salePrice;

    for (let rule of fbaFeeRules) {
      const withinMin = salePrice >= rule.min;
      const withinMax = rule.max === null || salePrice <= rule.max;

      if (withinMin && withinMax) {
        return rule.rate.toFixed(2);
      }
    }

    return 0;
  }, [productDetails]);

  const calculateStorageFee = useCallback(() => {
    const storageFeeRule = productDetails?.feeRules?.storageFee ?? 0;
    const storageMonths = productDetails?.profitCalculator?.storage_Months;
    return (storageFeeRule * storageMonths).toFixed(2);
  }, [
    productDetails?.feeRules?.storageFee,
    productDetails?.profitCalculator?.storage_Months,
  ]);

  const calculateMiscFeePercentageOfCost = () => {
    return (
      (productDetails?.profitCalculator?.totalFees?.miscFee_PercentageOfCost *
        productDetails?.profitCalculator?.costPrice) /
      100
    ).toFixed(2);
  };

  const calculateInboundPlacement = () => {
    const inboundPlacement =
      productDetails?.feeRules?.inboundPlacement?.splits ?? [];

    setInboundPlacementIndex((prev) => (prev + 1) % inboundPlacement.length);

    const current = inboundPlacement[inboundPlacementIndex];

    handleProfitTotalFees("inboundPlacement_O", current?.fee?.toFixed(2));

    return current;
  };

  const calculateEstimatedPayout = () => {
    const salePrice = productDetails?.profitCalculator?.salePrice;
    const { referralFee, fulfilmentFee, closingFee } =
      productDetails?.profitCalculator?.totalFees ?? {};

    return (salePrice - referralFee - fulfilmentFee - closingFee).toFixed(2);
  };

  const calculateProfit = () => {
    const salePrice = productDetails?.profitCalculator?.salePrice;
    const costPrice = productDetails?.profitCalculator?.costPrice;
    const totalFees = calculateTotalFees(
      productDetails?.profitCalculator?.totalFees,
      productDetails.profitCalculator?.costPrice
    );
    return (salePrice - costPrice - totalFees).toFixed(2);
  };

  const handleProfitTotalFees = (name: string, value: number) => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));
    updatedObj.profitCalculator.totalFees = {
      ...updatedObj.profitCalculator.totalFees,
      [name]: value,
    };

    setProductDetails(updatedObj);
  };

  const calculateAllFees = useCallback(() => {
    const updatedObj = JSON.parse(JSON.stringify(productDetails));

    updatedObj.profitCalculator.totalFees = {
      ...updatedObj.profitCalculator.totalFees,
      referralFee: calculateReferralFee(),
      fulfilmentFee: calculateFBAFee(),
      storageFee: calculateStorageFee(),
    };

    setProductDetails(updatedObj);
  }, [
    calculateFBAFee,
    calculateReferralFee,
    calculateStorageFee,
    productDetails,
    setProductDetails,
  ]);

  useEffect(() => {
    if (productDetails?.profitCalculator?.salePrice) {
      calculateAllFees();
    }
  }, [productDetails?.profitCalculator?.salePrice]);

  return {
    handleProfitTotalFees,
    calculateReferralFee,
    calculateFBAFee,
    calculateStorageFee,
    calculateMiscFeePercentageOfCost,
    calculateInboundPlacement,
    calculateEstimatedPayout,
    calculateProfit,
  };
};
